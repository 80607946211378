import React from 'react';
import { Typography } from '@material-ui/core';

const EmailText = () => {
  return (
    <>
      <Typography variant='body1'>Thank you for registering with us.</Typography>
      <br></br>
      <Typography variant='body1'>
        If you have an order that needs to be placed urgently, please email
        <a href='mailto:info@pacdocsign.com'> info@pacdocsign.com </a>
        or call (800)884-7587.
      </Typography>
      <br></br>
      <br></br>
      <Typography variant='body1'>Thank you,</Typography>
      <br></br>
      <Typography variant='body1'>PacDocSign Team</Typography>
    </>
  );
}

export { EmailText };
