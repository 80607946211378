import type { Theme } from '../../ThemeComponents/theme';
import { makeStyles } from '@material-ui/core';

export const marginStyles = makeStyles((theme: Theme) => ({
  mr1: {
    marginRight: 5
  },
  mr2: {
    marginRight: 10
  },
  mr3: {
    marginRight: 15
  },
  mr4: {
    marginRight: 20
  },
  mr5: {
    marginRight: 30
  },
  ml1: {
    marginLeft: 5
  },
  ml2: {
    marginLeft: 10
  },
  ml3: {
    marginLeft: 15
  },
  ml4: {
    marginLeft: 20
  },
  ml5: {
    marginLeft: 30
  },
  mt1: {
    marginTop: 5
  },
  mt2: {
    marginTop: 10
  },
  mt3: {
    marginTop: 15
  },
  mt4: {
    marginTop: 20
  },
  mt5: {
    marginTop: 30
  },
  mb1: {
    marginBottom: 5
  },
  mb2: {
    marginBottom: 10
  },
  mb3: {
    marginBottom: 15
  },
  mb4: {
    marginBottom: 20
  },
  mb5: {
    marginBottom: 30
  },
}));
