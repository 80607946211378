import { selector, selectorFamily } from 'recoil';

import { verifyAuthToken } from './Auth'
import { refetchNotifications } from '../Atoms/Notifications';

import { api } from '../../Services/api';

const fetchNotifications = selector({
  key: 'fetchNotifications',
  get: async ({ get }) => {
    try {
      get(refetchNotifications)
      await verifyAuthToken()
      const res: any = await api.GET(`announcements/getContactAnnouncement`);
      return {
        result: res.message.rows[0].announcement,
        success: true
      }
    } catch (error) {
      return {
        result: [],
        success: false
      }
    }
  }
});

const markAnnouncement = selectorFamily({
  key:'markAnnouncement',
  get: (params: any) => async () => {
    if (params) {
      try {
        await verifyAuthToken()
        const res: any = await api.POST('announcements/markContactAnnouncements', params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

export {
  fetchNotifications,
  markAnnouncement
};
