import React from 'react';
import { Grid, Typography, FormControlLabel, Radio } from '@material-ui/core';

const SignerType = ({ location, values, handleChange, disabled }) => {
  return (
    <>
      <Grid item md={4}>
        <Typography variant='body2'>Signer Type</Typography>
      </Grid>
      <Grid item md={4}>
        <FormControlLabel
          label='Notary'
          control={
            <Radio
              name={`${location}.Signer_Type`}
              value='Notary'
              checked={values.Signer_Type === 'Notary'}
              onChange={handleChange}
            />
          }
          disabled={disabled}
        />
      </Grid>
      <Grid item md={4}>
        <FormControlLabel
          label='Attorney'
          control={
            <Radio
              name={`${location}.Signer_Type`}
              value='Attorney'
              checked={values.Signer_Type === 'Attorney'}
              onChange={handleChange}
            />
          }
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export { SignerType };
