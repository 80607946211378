import * as _ from 'lodash';

const mapper = {
  f_appointment_date: 'Appointment Date',
  f_appointment_time: 'Appointment Time',
  w_Appointment_Time_type: 'Appointment Time Type',
  w_Borrower_First_Name: 'Borrower First Name',
  w_Borrower_Last_Name: 'Borrower Last Name',
  w_Co_Borrower_First_Name: 'Co-Borrower First Name',
  w_Co_Borrower_Last_Name: 'Co-Borrower Last Name',
  Signer_Type: 'Signer Type',
  Partial_Signing: 'Partial Signing',
  additional_client_email: 'Additional Client Email',
  w_Signing_Location_Name: 'Signing Location Name',
  w_Signing_Street_Address: 'Address',
  w_Signing_City: 'City',
  w_Signing_State: 'State',
  w_Signing_Zip: 'Zip Code',
  property_address: 'Property Address',
  property_city: 'Property City',
  property_state: 'Property State',
  property_zip: 'Property Zip Code',
  w_Signing_Phone_Number: 'Signing Phone',
  w_Borrower_Home_Phone: 'Signing Home Phone',
  f_borrower_email: 'Borrower Email',
  w_Additional_Information: 'Additional Information'
};

const getDifference = (newProps, oldProps) => {
  const changes = (object, base) => {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }

  const diff: any = changes(newProps, oldProps);
  let str = '<p>';
  for (const i in diff) {
    if (diff.hasOwnProperty(i)) {
      if (mapper[i]) {
        if (i === 'w_Appointment_Time_type') {
          const oldValue = oldProps[i] === 'OT' ? 'Open Time' : 'Selected Time';
          const newValue = diff[i] === 'OT' ? 'Open Time' : 'Selected Time';
          str += mapper[i] + ' = ' + oldValue + ' ==> ' + newValue + '<br>';
        } else {
          str += mapper[i] + ' = ' + oldProps[i] + ' ==> ' + diff[i] + '<br>';
        }
      }
    }
  }

  str = str + '</p>';

  return str;
};

export { getDifference };
