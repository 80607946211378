import React, { useState, useRef } from 'react';
import { useRecoilValue, useRecoilCallback } from 'recoil';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik, Form } from 'formik';
import moment from 'moment';

import { OrdersForm } from './OrderForm';
import { SingleLoanNumber } from './Fields/SingleLoanNumber';

import { currentOrder } from '../../Recoil/Atoms/Orders';
import { userInfo } from '../../Recoil/Atoms/Login';
import { createOrder, sendMailToPacdoc } from '../../Recoil/Selectors/Orders';

import { locationOne, validationSchemaForExisting } from '../../Common/Rules/OrdersForm';
import { createPayload } from '../../Common/CreatePayload';
import { updateAdditionalBorrowers } from '../../Common/UpdateAdditionalBorrowers';

const DuplicateOrder = ({ bindSubmitForm, handleLoading }) => {
  const alertRef = useRef(null);
  const [alert, setAlert] = useState(null);
  const order: any = useRecoilValue(currentOrder);
  const user = useRecoilValue(userInfo);

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { resetForm }) => {
    values.w_Loan_Number = [values.location_one.w_Loan_Number];
    const params = createPayload(values);
    const updatedAdditionalBorrowers = updateAdditionalBorrowers(values, order);
    params.location_one.additionalBorrowersModel = [...updatedAdditionalBorrowers];
    handleLoading(true);

    delete params.location_one.w_Loan_Number;
    delete params.location_one.Id;

    const requiredkeys = ['f_appointment_date', 'f_borrower_first_name', 'f_borrower_last_name']

    Object.keys(params.location_one).map(key => {
      if (!locationOne.hasOwnProperty(key) && !requiredkeys.includes(key)) {
        delete params.location_one[key]
      }
      return key
    })
    params.location_one.w_sql_appointment_date = moment(values.location_one.w_Appointment_Date).format('YYYY-MM-DD')
    params.location_one.f_status_web = 'In Process'

    const res = await snapshot.getPromise(createOrder(params));
    snapshot.getPromise(sendMailToPacdoc({
      loan: params.w_Loan_Number[0],
      status: 'inserted',
      client: user.Contact_Email,
      date: params.location_one.f_appointment_date,
      time: params.location_one.f_appointment_time
    }));

    if (res.success) {
      resetForm();
      handleLoading(false, 'Order Duplicated Successfully!', 'success');
    } else {
      setAlert({
        status: 'error',
        message: res.result.data.message
      });
      alertRef.current.scrollIntoView();
      handleLoading(false);
    }
  });

  return (
    <Formik
      initialValues={{ location_one: { ...order, w_Appointment_Time: '', w_Appointment_Date: '' } }}
      validationSchema={validationSchemaForExisting}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        submitForm
      }) => {
        bindSubmitForm(submitForm);
        return (
          <Form onSubmit={handleSubmit}>
            <SingleLoanNumber
              value={values.location_one.w_Loan_Number}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              disabled={false}
            />
            <OrdersForm
              location='location_one'
              values={{ ...values.location_one }}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              disabled={false}
            />
            { alert &&
              <Box mt={3}>
                <Alert ref={alertRef} severity={alert.status}>{alert.message}</Alert>
              </Box>
            }
          </Form>
        )
      }}
    </Formik>
  );
};

export { DuplicateOrder };
