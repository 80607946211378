import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    removeSelectMargin: {
      marginTop: 0,
      marginBottom: 0
    }
  })
);

export { useStyles };
