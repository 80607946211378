import React from 'react';
import { Grid, TextField, FormControl, InputLabel, NativeSelect,  OutlinedInput } from '@material-ui/core';

import { states } from '../../../Common/States';

import { useStyles } from '../Styles/Fields';


const SignerAddress = ({ location, values, errors, touched, handleChange, handleBlur, setFieldValue, disabled }) => {
  const classes = useStyles();

  return (
    <>

      <Grid item xs={12} sm={12} md={4}>
        <TextField
          label='Address'
          name={`${location}.w_Signing_Street_Address`}
          variant='outlined'
          size='small'
          fullWidth
          value={values.w_Signing_Street_Address}
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].w_Signing_Street_Address}
          error={
            Boolean((touched[location] && errors[location]) && errors[location].w_Signing_Street_Address)
          }
          inputProps={{
            autoComplete: 'none'
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          label='Suite #'
          name={`${location}.suite_nr`}
          variant='outlined'
          size='small'
          fullWidth
          margin='none'
          value={values.suite_nr}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          label='City'
          name={`${location}.w_Signing_City`}
          variant='outlined'
          size='small'
          fullWidth
          margin='none'
          value={values.w_Signing_City}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].w_Signing_City}
          error={Boolean((touched[location] && errors[location]) && errors[location].w_Signing_City)}
          disabled={disabled}
        />
      </Grid>


      <Grid item xs={12} sm={6} md={4}>
        <FormControl className={classes.removeSelectMargin} fullWidth margin='dense' variant='outlined'>
        <InputLabel  >State</InputLabel>
          <NativeSelect
            name={`${location}.w_Signing_State`}
            fullWidth
            onChange={handleChange}
            disabled={disabled}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          >
            <option disabled selected hidden>
              </option>
            {states.map((state) =>
              <option key={`borrower-${state.code}`} value={state.code}>
                {state.name} - {state.code}
              </option>
            )}
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label='Zip Code'
          name={`${location}.w_Signing_Zip`}
          variant='outlined'
          size='small'
          fullWidth
          margin='none'
          value={values.w_Signing_Zip}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={(touched[location] && errors[location]) && errors[location].w_Signing_Zip}
          error={Boolean((touched[location] && errors[location]) && errors[location].w_Signing_Zip)}
          disabled={disabled}
        />
      </Grid>
      <Grid className={classes.pRelative} item xs={12} sm={12} md={4}>
        <TextField
          label='Signing Location Name'
          name={`${location}.w_Signing_Location_Name`}
          variant='outlined'
          size='small'
          fullWidth
          margin='none'
          value={values.w_Signing_Location_Name}
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export { SignerAddress };
