import { makeStyles } from '@material-ui/core/styles';

export const cuStyles = makeStyles(() => ({
  mapContainer:{
    flex: 1,
    width: '100%',
    minHeight: 300,
    height: 480,
  },
  mapElement: {
    width: '100%',
    height: 480
  }
}));
