import React from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import InputMask from 'react-input-mask';

const Fields = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <TextField
          label='Company Name'
          name='company_name'
          variant='outlined'
          size='small'
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.company_name && errors.company_name}
          error={Boolean(touched.company_name && errors.company_name)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Division Name'
          name='divisionName'
          variant='outlined'
          size='small'
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.divisionName && errors.divisionName}
          error={Boolean(touched.divisionName && errors.divisionName)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='First Name'
          name='Contact_First_Name'
          variant='outlined'
          size='small'
          fullWidth
          onChange={e => {
            e.target.value =
              e.target.value.charAt(0).toUpperCase() +
              e.target.value.substr(1).toLowerCase();
            handleChange(e);
          }}
          onBlur={handleBlur}
          helperText={touched.Contact_First_Name && errors.Contact_First_Name}
          error={Boolean(
            touched.Contact_First_Name && errors.Contact_First_Name
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Last Name'
          name='Contact_Last_Name'
          variant='outlined'
          size='small'
          fullWidth
          onChange={e => {
            e.target.value =
              e.target.value.charAt(0).toUpperCase() +
              e.target.value.substr(1).toLowerCase();
            handleChange(e);
          }}
          onBlur={handleBlur}
          helperText={touched.Contact_Last_Name && errors.Contact_Last_Name}
          error={Boolean(touched.Contact_Last_Name && errors.Contact_Last_Name)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputMask
          mask='(999) 999-9999'
          maskChar=''
          value={values.Contact_Work_Phone}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {() => (
            <TextField
              label='Work Phone'
              name='Contact_Work_Phone'
              variant='outlined'
              size='small'
              fullWidth
              helperText={
                touched.Contact_Work_Phone && errors.Contact_Work_Phone
              }
              error={Boolean(
                touched.Contact_Work_Phone && errors.Contact_Work_Phone
              )}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12}>
        <InputMask
          mask='(999) 999-9999'
          maskChar=''
          value={values.Contact_Cell}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {() => (
            <TextField
              label='Mobile Number'
              name='Contact_Cell'
              variant='outlined'
              size='small'
              fullWidth
              helperText={touched.Contact_Cell && errors.Contact_Cell}
              error={Boolean(touched.Contact_Cell && errors.Contact_Cell)}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Email'
          name='Contact_Email'
          variant='outlined'
          size='small'
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.Contact_Email && errors.Contact_Email}
          error={Boolean(touched.Contact_Email && errors.Contact_Email)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Team Manager'
          name='Contact_Team_Manager'
          variant='outlined'
          size='small'
          fullWidth
          onChange={e => {
            e.target.value = e.target.value
              .toLowerCase()
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            handleChange(e);
          }}
          onBlur={handleBlur}
          helperText={
            touched.Contact_Team_Manager && errors.Contact_Team_Manager
          }
          error={Boolean(
            touched.Contact_Team_Manager && errors.Contact_Team_Manager
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth margin='dense' variant='outlined'>
          <InputLabel>Authentication Type</InputLabel>
          <Select
            label='Authentication Type'
            name='authenticationType'
            fullWidth
            defaultValue={values.authenticationType}
            onChange={handleChange}
            error={Boolean(
              touched.authenticationType && errors.authenticationType
            )}
          >
            <MenuItem value='password'>Password</MenuItem>
            <MenuItem value='two-factor'>Two Factor</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export { Fields };
