import React, { Suspense } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { PageLoad } from '../Components/LoadingScreen/PageLoad';

import { Orders } from '../Pages/Orders';
import { HoursOfOperations } from '../Pages/HoursOfOperations';
import { ContactUs } from '../Pages/ContactUs';
import { ChangePassword } from '../Pages/ChangePassword';
import { MyProfile } from '../Pages/MyProfile';

const BasePage = () => {
  return (
    <Suspense fallback={<PageLoad />}>
      <Routes>
      <Route path='/' element={<Navigate to='/orders' />} />
      <Route path='/login' element={<Navigate to='/orders' />} />
      <Route path='/login-password' element={<Navigate to='/orders' replace/>} />


        <Route path='/orders' element={<Orders />} />
        <Route path='/hours-of-operations' element={<HoursOfOperations />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path='/my-profile' element={<MyProfile />} />

        <Route path='*' element={<Navigate to='/404' />} />
      </Routes>
    </Suspense>
  );
}

export { BasePage };
