import * as Yup from 'yup';

export const initialValues = {
  password: '',
  submit: null
};

export const passwordSchema = Yup.object().shape({
  password: Yup
    .string()
    .min(7, 'Must be at least 7 characters')
    .max(255)
    .required('Password is required')
});
