import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15
    },
    grid: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      marginTop: 8,
      marginBottom: 4,
      marginRight: 15
    },
    select: {
      minWidth: 250
    },
    selectBox: {
      display: 'flex',
      flex: 1,
    },
  })
);

export { useStyles };
