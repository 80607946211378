import React from 'react';
import { Grid, TextField } from '@material-ui/core';

import { useStyles } from '../Styles/Fields';

const SingleLoanNumber = ({ value, errors, touched, handleChange, handleBlur, disabled }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridRoot} container spacing={2}>
      <Grid item xs={12} md={4}>
        <TextField
          label='Loan Number'
          name='location_one.w_Loan_Number'
          variant='outlined'
          size='small'
          fullWidth
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.w_Loan_Number && errors.w_Loan_Number}
          error={Boolean(touched.w_Loan_Number && errors.w_Loan_Number)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

export { SingleLoanNumber };
