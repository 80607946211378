import axios from 'axios';
import cookie from 'react-cookies';


let FileServiceURL = 'https://pacdocv2-api.wl.r.appspot.com'
if (process.env.REACT_APP_PROD === '1') {
  FileServiceURL = 'https://pacdocv2-api-prod.uc.r.appspot.com'
}

const createBaseURL = () => {
  return `https://${process.env.REACT_APP_REGION}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/`
  // return 'http://localhost:8080/'
}

const instance = axios.create({
  baseURL: createBaseURL()
});

const file = axios.create({
  baseURL: FileServiceURL
});

const download = axios.create({
  baseURL: FileServiceURL,
  responseType: 'blob',
});

const apiDownload = axios.create({
  baseURL: createBaseURL(),
  responseType: 'blob',
});

const fileHandler = axios.create({
  baseURL: 'https://pacdocv2-api-prod.uc.r.appspot.com/',
  responseType: 'blob',
});

instance.interceptors.request.use((config): any => {
  const token = cookie.load('accessToken');
  config.headers.accessToken = token ? `Bearer ${token}` : '';
  return config;
});

download.interceptors.request.use((config): any => {
  const token = cookie.load('accessToken');
  config.headers.accessToken = token ? `Bearer ${token}` : '';
  return config;
});


apiDownload.interceptors.request.use((config): any => {
  const token = cookie.load('accessToken');
  config.headers.accessToken = token ? `Bearer ${token}` : '';
  return config;
});

fileHandler.interceptors.request.use((config): any => {
  const token = cookie.load('accessToken');
  config.headers.accessToken = token ? `Bearer ${token}` : '';
  return config;
});

file.interceptors.request.use((config): any => {
  const token = cookie.load('accessToken');
  config.headers.accessToken = token ? `Bearer ${token}` : '';
  return config;
});

const fileapi = {
  GET: (route: string, params?: any) => {
    params = params || {};

    const promise = (resolve, reject) => {
      return file
        .get(route, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    };

    return new Promise(promise);
  },
  POST: (route, params = null) => {
    const promise = (resolve, reject) => {
      file
        .post(route, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    };

    return new Promise(promise);
  },
  PUT: (route, params = null) => {
    const promise = (resolve, reject) => {
      file
        .put(route, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    };

    return new Promise(promise);
  },
  DELETE: route => {
    const promise = (resolve, reject) => {
      file
        .delete(route)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    };
    return new Promise(promise);
  },
  DOWNLOAD: (route, params = null) => {
    const promise = (resolve, reject) => {
      download
        .post(route, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    };

    return new Promise(promise);
  },
  GET_DOWNLOAD: (route, params = null) => {
    const promise = (resolve, reject) => {
      download
        .get(route, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    };

    return new Promise(promise);
  }
};

const api = {
  GET: (route) => {
    const promise = (resolve, reject) => {
      return instance.get(route)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    };

    return new Promise(promise);
  },
  POST: (route, params = null) => {
    const promise = (resolve, reject) => {
      instance.post(route, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    };

    return new Promise(promise);
  },
  PUT: (route, params = null) => {
    const promise = (resolve, reject) => {
      instance.put(route, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err?.response?.data);
        });
    };

    return new Promise(promise);
  },
  DELETE: (route) => {
    const promise = (resolve, reject) => {
      instance.delete(route)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    };
    return new Promise(promise);
  },
  DOWNLOAD: (route, params = null) => {
    const promise = (resolve, reject) => {
    download.post(route, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.response);
      });
    };

    return new Promise(promise);
  },
  HANDLE_FILE: (route, params = null) => {
    const promise = (resolve, reject) => {
      fileHandler.post(route, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
      };
  
      return new Promise(promise);
  },

  GET_DOWNLOAD: (route, params = null) => {
    const promise = (resolve, reject) => {
      apiDownload
        .get(route, params)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    };

    return new Promise(promise);
  }
};

export { api, fileapi };
