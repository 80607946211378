import React, { useState, useRef } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { Box, Tabs, Tab } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik, Form } from 'formik';

import { OrdersForm } from './OrderForm';
import { SplitClosing } from './Fields/SplitClosing';
import { LoanNumber } from './Fields/LoanNumber';
import { division } from './../../Recoil/Atoms/Divisions';
import { userInfo } from '../../Recoil/Atoms/Login';
import { resendMailToPacdoc } from './../../Recoil/Atoms/Orders'

import { createOrder, sendMailToPacdoc } from '../../Recoil/Selectors/Orders';

import {
  fLoanNumber,
  locationOne,
  locationTwo,
  validationSchemaWithoutSplit,
  validationSchemaWithSplit
} from '../../Common/Rules/OrdersForm';
import { createPayload } from '../../Common/CreatePayload';

import { useStyles } from './Styles/Fields';

const a11yProps = (tab: any) => {
  return {
    id: `order-tab-${tab}`,
    'aria-controls': `order-tabpanel-${tab}`,
  };
};

const TabPanel = ({ tab, index, children }) => {
  return (
    <div
      role='tabpanel'
      hidden={tab !== index}
      id={`order-tabpanel-${tab}`}
      aria-labelledby={`order-tab-${tab}`}
    >
      {children}
    </div>
  )
};

const NewOrder = ({ bindSubmitForm, handleLoading }) => {
  const classes = useStyles();
  const alertRef = useRef(null);
  const [closing, handleClosing] = useState('');
  const [tab, handleTab] = useState(0);
  const [alert, setAlert] = useState(null);
  const resendEmail = useSetRecoilState(resendMailToPacdoc)

  const divisionID = useRecoilValue(division);
  const user = useRecoilValue(userInfo);

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { resetForm }) => {
    values.location_one.division_id = divisionID;
    if (closing !== 'SC') {
      delete values.location_two;
    } else {
      values.location_two.division_id = divisionID;
    }

    handleLoading(true);
    const params = createPayload(values);
    const res: any = await snapshot.getPromise(createOrder(params));
    resendEmail(n => n + 1)
    snapshot.getPromise(sendMailToPacdoc({
      loan: params.w_Loan_Number[0],
      status: 'inserted',
      client: user.Contact_Email,
      date: params.location_one.f_appointment_date,
      time: params.location_one.f_appointment_time
    }));

    if (res.success) {
      resetForm();
      handleLoading(false, 'Order Created Successfully!', 'success');
    } else {
      setAlert({
        status: 'error',
        message: res.result.data.message
      });
      alertRef.current.scrollIntoView();
      handleLoading(false);
    }
  });

  return (
    <Formik
      initialValues={{
        w_Loan_Number: fLoanNumber,
        location_one: locationOne,
        location_two: locationTwo
      }}
      validationSchema={closing === 'SC' ? validationSchemaWithSplit : validationSchemaWithoutSplit}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        submitForm
      }: any) => {
        bindSubmitForm(submitForm);
        return (
          <Form onSubmit={handleSubmit}>
            <SplitClosing
              closing={closing}
              handleClosing={handleClosing}
              setFieldValue={setFieldValue}
              values={values}
            />
            { closing === 'SC' ?
              <>
                <Tabs
                  className={classes.mb20}
                  value={tab}
                  centered
                  variant='fullWidth'
                  onChange={(e, t) => handleTab(t)}
                >
                  <Tab label='Location One' {...a11yProps(0)} />
                  <Tab label='Location Two' className={`${( errors.location_two )  && classes.errorIndicator}`} {...a11yProps(1)} disabled={closing !== 'SC'} />
                </Tabs>
                <TabPanel tab={tab} index={0}>
                  <LoanNumber
                    closing={closing}
                    values={values.w_Loan_Number}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    disabled={false}
                  />
                  <OrdersForm
                    location='location_one'
                    values={{ ...values.location_one }}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    disabled={false}
                  />
                </TabPanel>
                <TabPanel tab={tab} index={1}>
                  <OrdersForm
                    location='location_two'
                    values={{ ...values.location_two }}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    disabled={false}
                  />
                </TabPanel>
              </> :
              <>
                <LoanNumber
                  closing={closing}
                  values={values.w_Loan_Number}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  disabled={false}
                />
                <OrdersForm
                  location='location_one'
                  values={{ ...values.location_one }}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  disabled={false}
                />
              </>
            }
            { alert &&
              <Box mt={3}>
                <Alert ref={alertRef} severity={alert.status}>{alert.message}</Alert>
              </Box>
            }
            { ( errors.location_two || errors.location_one ) &&
              <Box mt={3}>
                <Alert ref={alertRef} severity={'error'}>
                  {`Please fill all the required fields in location ${( errors.location_one && !(errors.location_two) ) ? 'one' : 'two'}`}
                </Alert>
              </Box>
            }
          </Form>
        )
      }}
    </Formik>
  );
};

export { NewOrder };
