import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Button, FormHelperText, TextField, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';

import { initialValues, emailSchema } from '../../Common/Rules/LoginEmail';
import { marginStyles } from '../../Common/Styles/Margins';

interface HandleEmail {
  submitEmail: (params) => Promise<void>
};

const EmailForm: FC<HandleEmail> = (props) => {
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);

  const handleEmail = async (values, { resetForm, setSubmitting }) => {
    await props.submitEmail({
      email: values.email,
      setSubmitting,
      resetForm,
      setAlert
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={emailSchema}
      onSubmit={handleEmail}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        errors
      }) => (
        <form onSubmit={handleSubmit}>
          { alert &&
            <Box mb={3}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          }
          <TextField
            label='Email'
            name='email'
            type='text'
            variant='outlined'
            fullWidth
            autoFocus
            onChange={ (e) => { e.target.value = e.target.value.trim(); handleChange(e) } }
            onBlur={handleBlur}
            value={values.email}
            helperText={touched.email as Boolean && errors.email as String}
            error={Boolean(touched.email && errors.email)}
          />
          {errors.submit && (
            <FormHelperText error>
              {errors.submit as String}
            </FormHelperText>
          )}
          <Box mt={3}>
            <Button
              type='submit'
              size='large'
              color='primary'
              variant='contained'
              fullWidth
              disabled={isSubmitting}
            >
              {isSubmitting && <CircularProgress className={marginClasses.mr2} color='inherit' size={25} />}
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export { EmailForm };
