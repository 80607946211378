import React, { useState, useEffect } from 'react';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilCallback
} from 'recoil';
import { Typography } from '@material-ui/core';

import { Page } from '../Components/Page';
import { GeneralModal } from '../Components/Modal';
import { Actions } from '../Components/Orders/Actions';
import { OrdersList } from '../Components/Orders/OrdersList';
import { NewOrder } from '../Components/Orders/NewOrder';
import { EditOrder } from '../Components/Orders/EditOrder';
import { ViewOrder } from '../Components/Orders/ViewOrder';
import { DuplicateOrder } from '../Components/Orders/DuplicateOrder';
import { Toast } from '../Components/Toasts';

import { currentOrder, refetchOrders } from '../Recoil/Atoms/Orders';
import { userPrivilege, division } from '../Recoil/Atoms/Divisions';
import { userInfo } from '../Recoil/Atoms/Login';
import { fetchOrders, cancelOrder, sendMailToPacdoc } from '../Recoil/Selectors/Orders';
import { fetchPrivilege } from '../Recoil/Selectors/Divisions';

import { useStyles } from './Styles/Page';

const Orders = () => {
  let Form = null;
  const classes = useStyles();

  const [title, handleTitle] = useState('');
  const [modal, handleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const setDivision = useSetRecoilState(division)
  const [divisionOrders, setDivisionOrders] = useState([])
  const [totalOrders, setTotalOrders] = useState(0)

  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const setUserPrivilege = useSetRecoilState(userPrivilege);
  const callRefetch = useSetRecoilState(refetchOrders);

  const order: any = useRecoilValue(currentOrder);
  const user: any = useRecoilValue(userInfo);

  const privilegeInfo: any = useRecoilValueLoadable(fetchPrivilege);
  const ordersData: any = useRecoilValueLoadable(fetchOrders);
  const privilege = privilegeInfo?.contents?.result || {};
  const divisions = user?.user?.division || user?.division || [] // divisionsData?.contents?.result || [];
  const[searchClicked, setsearchClicked]=useState(false);

  // const orders = ordersData?.contents?.result?.rows || [];
  // const total = ordersData?.contents?.result?.count || 0;
  const refetch = () => callRefetch((n) => n + 1);

  useEffect(()=> {
    if (divisions.length === 1)
      setDivision(divisions[0].divisionID)
  }, [divisions, setDivision])

  useEffect(() => {
    if (privilege.privilege) {
      setUserPrivilege(privilege.privilege)
    }
  }, [privilege, setUserPrivilege]);

  useEffect(()=> {
    if (ordersData.state !== 'loading'){
      setDivisionOrders(ordersData?.contents?.result?.rows || [])
      setTotalOrders(ordersData?.contents?.result?.count)
    }
  }, [ordersData.state, setDivisionOrders, ordersData])

  const openModal = (modalTitle, open) => {
    handleTitle(modalTitle);
    handleModal(open);
  };

  const handleSubmit = (e) => {
    if (title === 'Cancel Order') {
      handleCancel();
    }

    if (Form) {
      Form(e);
    }
  };

  const bindSubmitForm = (submitForm) => {
    Form = submitForm;
  };

  const handleLoading = (value, message, type) => {
    setLoading(value);
    if (!value && message) {
      openModal('', false);
      setToast({
        message,
        type,
        open: true
      });
      refetch();
    }
  };

  const handleCancel = useRecoilCallback(({ snapshot }) => async () => {
    const res = await snapshot.getPromise(cancelOrder({ ...order }));
    snapshot.getPromise(sendMailToPacdoc({
      loan: order.w_Loan_Number,
      status: 'canceled',
      client: user.Contact_Email,
      date: order.f_appointment_date,
      time: order.f_appointment_time
    }));
    if (res.success) {
      handleLoading(false, 'Order Cancelled Successfully!', 'success');
    } else {
      handleLoading(false, 'Unable to Cancel the Order!', 'error');
    }
  })

  return (
    <Page className={classes.root} title='Orders'>
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
      <Actions divisions={divisions} openModal={openModal} SearchStateChanger={setsearchClicked}/>
      <OrdersList
        orders={divisionOrders}
        loading={( ordersData?.state === 'loading' && divisionOrders.length === 0 ) || privilegeInfo.state === 'loading' || searchClicked === true}
        orderLoading={ordersData?.state === 'loading'}
        total={totalOrders}
        openModal={openModal}
        ordersRefetch={callRefetch}
      />
      { modal &&
        <GeneralModal
          open={modal}
          title={title}
          loading={loading}
          openModal={openModal}
          handleSubmit={handleSubmit}
          noSubmit={title === 'View Order'}
        >
          {title === 'View Order' &&
            <ViewOrder />
          }
          {title === 'Edit Order' &&
            <EditOrder bindSubmitForm={bindSubmitForm} handleLoading={handleLoading} />
          }
          {title === 'Cancel Order' &&
            <Typography variant='body2'>Are you sure you want to cancel this order?</Typography>
          }
          {title === 'Duplicate Order' &&
            <DuplicateOrder bindSubmitForm={bindSubmitForm} handleLoading={handleLoading} />
          }
          {title === 'New Order' &&
            <NewOrder bindSubmitForm={bindSubmitForm} handleLoading={handleLoading} />
          }
        </GeneralModal>
      }
    </Page>
  );
}

export { Orders };
