import React, { useState } from 'react'
import { useRecoilCallback } from 'recoil'
import { Typography, Grid, MenuItem, TextField } from '@material-ui/core'
import { useSnackbar } from 'notistack'

import { GeneralModal } from '../Modal'
import { editOrder } from '../../Recoil/Selectors/Orders'

export const TrackingStatus = ((props: any) => {
  const { handleTrackIngStatus, order, setRefetchOrders } = props
  const [trackingNumber, setTrackingNumber] = useState(order?.w_Return_Tracking)
  const [status, setStatus] = useState(order?.S2C_Tracking_Status || '')
  const { enqueueSnackbar } = useSnackbar()

  const [comment, setComment] = useState(order?.S2C_Tracking_Note)

  const [loading, setLoaing] = useState(false)

  const statusOptions = [
    { value: 'DE', label: 'Delivered' },
    { value: 'HD', label: 'Hand Delivered' },
    { value: 'BD', label: 'Borrower Delivered' },
    { value: 'LB', label: 'Left With Borrower' },
    { value: 'IT', label: 'In Transit' },
    { value: 'SC', label: 'See Comments' }
  ]


  const onSubmit = useRecoilCallback(({ snapshot }) => async () => {
    if (!status || !trackingNumber || (status === 'SC' && !comment)) {
      enqueueSnackbar(
        'Please fill all the fields',
        {
          variant: 'error'
        }
      );
      return
    }

    setLoaing(true)
    const res: any = await snapshot.getPromise(editOrder({
      w_Loan_Number: [],
      location_one: {
        S2C_Tracking_Status: status,
        w_Return_Tracking: trackingNumber,
        Id: order?.Id,
        S2C_Tracking_Note: comment
      }
    }));
    if (res.success) {
      setComment('')
      setStatus('')
      handleTrackIngStatus(false)
      setRefetchOrders(n => n + 1)
      setLoaing(false)
      enqueueSnackbar(
        'Order details updated',
        {
          variant: 'success'
        }
      );
    }
    else {
      handleTrackIngStatus(false)
      setLoaing(false)
      enqueueSnackbar(
        res?.result?.message || 'Error occurred',
        {
          variant: 'error'
        }
      );
    }
  })
  return (
    <GeneralModal
      loading={loading}
      maxWidth='sm'
      open={order}
      title='Signer To Client Tracking Status'
      openModal={e => handleTrackIngStatus(null)}
      submitLabel='Submit'
      handleSubmit={onSubmit}
    >
      <Grid container xs={12} spacing={3}>
        <Grid container item justify='space-between'>
          <Typography>
            <b>
              Loan Number
          </b>
          </Typography>
          <Typography>
            {order?.w_Loan_Number}
          </Typography>
        </Grid>
        <Grid container item justify='space-between'>
          <TextField
            label='Tracking #'
            size='small'
            variant='outlined'
            fullWidth
            value={trackingNumber}
            onChange={e => setTrackingNumber(e.target.value)}
          >
          </TextField>
        </Grid>
        <Grid container item>
          <TextField
            size='small'
            label='Signer To Client Tracking status'
            variant='outlined'
            fullWidth
            select
            SelectProps={{
              value: status,
              onChange: (e) => setStatus(e.target.value),
              multiline: true
            }}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {status === 'SC' && <Grid container item>
          <TextField
            multiline
            rows={3}
            label='Enter your comment'
            size='small'
            variant='outlined'
            fullWidth
            value={comment}
            onChange={e => setComment(e.target.value)}
          >
          </TextField>
        </Grid>}

      </Grid>

    </GeneralModal>
  )
})