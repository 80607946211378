import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect } from 'react';
import { paddingStyles } from 'src/Common/Styles/Paddings';
import { useStyles } from '../Styles/Fields';


const SplitClosing = ({ closing, setFieldValue, values, handleClosing }) => {
  const classes = useStyles();
  const paddingClasses = paddingStyles()
  const onChange = e => {
    handleClosing(e.target.value)
    if (e.target.value === 'MLC' && values?.w_Loan_Number?.length < 2) {
      setFieldValue('w_Loan_Number', [...values?.w_Loan_Number, ''])
    } else if (e.target.value !== 'MLC' && values?.w_Loan_Number?.length > 1) {
      setFieldValue('w_Loan_Number', [values?.w_Loan_Number[0]])
    }
    setFieldValue('location_one.Closing_Type',e.target.value)
  }
  useEffect(() => {
    if (closing === 'MLC' && values?.w_Loan_Number?.length < 2) {
      setFieldValue('w_Loan_Number', [...values?.w_Loan_Number, ''])
    }
    // eslint-disable-next-line
  }, [values?.w_Loan_Number, closing])

  return (
    <RadioGroup
      className={classes.splitCheckbox}
      row
      aria-label='position'
      name='location_one.Closing_Type'
      defaultValue='FC'
      onChange={onChange}
    >
       <FormControlLabel
        className={paddingClasses.pl3}
        value='FC'
        control={<Radio color='primary' />}
        label='Single Closing'
        labelPlacement='start'
      />
      <FormControlLabel
        className={paddingClasses.pl3}
        value='P'
        control={<Radio color='primary' />}
        label='Partial Closing'
        labelPlacement='start'
      />
      <FormControlLabel

        value='SC'
        control={<Radio color='primary' />}
        label='Split Closing'
        labelPlacement='start'
      />
      <FormControlLabel
        className={paddingClasses.pl3}
        value='MLC'
        control={<Radio color='primary' />}
        label='Multiple Closings'
        labelPlacement='start'
      />
     
    </RadioGroup>
  );
}

export { SplitClosing };
