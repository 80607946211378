import React from 'react';
import { Grid, TextField, Button, SvgIcon } from '@material-ui/core';
import { FieldArray } from 'formik';
import { Plus, XCircle } from 'react-feather';

import { useStyles } from '../Styles/Fields';

const LoanNumber = ({ closing, values, errors, touched, handleChange, handleBlur, setFieldValue, disabled }) => {
  const classes = useStyles();
  return (
    <>
      <Grid className={classes.gridRoot} container spacing={2}>
        <FieldArray
          name='w_Loan_Number'
          render={(helper) => (
            closing !== 'MLC' ? (
            <Grid className={classes.pRelative} key={`w_Loan_Number - 0`} item xs={12} sm={6} md={4}>
            <TextField
              label='Loan Number'
              name={`w_Loan_Number[0]`}
              variant='outlined'
              size='small'
              fullWidth
              defaultValue={values[0].num}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(
                (touched.w_Loan_Number && touched.w_Loan_Number[0]) &&
                (errors.w_Loan_Number && errors.w_Loan_Number[0])
              )}
              disabled={disabled}
            /> 
            </Grid>)
            :
            values.map((num, i) =>
              (<Grid className={classes.pRelative} key={`w_Loan_Number - ${i}`} item xs={12} sm={6} md={4}>
                <TextField
                  label='Loan Number'
                  name={`w_Loan_Number[${i}]`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  defaultValue={num}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    closing === 'MLC' && i === 1 && errors.w_Loan_Number && errors?.w_Loan_Number?.length === 2
                      && touched.w_Loan_Number && touched.w_Loan_Number[i] && errors.w_Loan_Number[i] ?
                      'Multiple Loan numbers are required, because you have chosen multiple closings' :
                      (touched.w_Loan_Number && touched.w_Loan_Number[i]) &&
                      (errors.w_Loan_Number && errors.w_Loan_Number[i])
                  }
                  error={Boolean(
                    (touched.w_Loan_Number && touched.w_Loan_Number[i]) &&
                    (errors.w_Loan_Number && errors.w_Loan_Number[i])
                  )}
                  disabled={disabled}
                />
                {closing === 'MLC' ? i > 1 : i > 0 &&
                  <SvgIcon
                    className={classes.closeButton}
                    fontSize='small'
                    color='primary'
                    onClick={() => helper.remove(i)}
                  >
                    <XCircle />
                  </SvgIcon>
                }
              </Grid>)
            )
          )}
        />
        {!disabled && closing === 'MLC' &&
          <Grid item xs={12} sm={6} md={4}>
            <Button
              color='primary'
              variant='contained'
              onClick={() => setFieldValue(`w_Loan_Number[${values.length}]`, '')}
            >
              <SvgIcon fontSize='small'><Plus /></SvgIcon> Add Loan #
            </Button>
          </Grid>
        }
      </Grid>
    </>
  );
}

export { LoanNumber };
