import React, { useState } from 'react';
import { Box, Card, CardContent, Container, Typography } from '@material-ui/core';

import { Page } from '../Components/Page';
import { RegisterForm } from '../Components/Register/RegisterForm';
import { EmailText } from '../Components/Register/EmailText';
import { RegisterText } from '../Components/Register/RegisterText';

import { useStyles } from './Styles/Login';

const Register = () => {
  const classes = useStyles();
  const [register, handleRegister] = useState('');

  return (
    <Page className={classes.root} title='Contact Register'>
      <Container className={classes.cardContainer} maxWidth='sm'>
        <Card>
          <CardContent className={classes.cardContent}>
            <div className={classes.currentMethodIcon}>
              <img alt='LOGO' src='/static/Logo.png' />
            </div>
            {register === 'email' ?
              <EmailText /> :
              register === 'signup' ?
                <RegisterText /> :
                <>
                  <Box alignItems='center' display='flex' justifyContent='space-between'>
                    <Typography color='textPrimary' gutterBottom variant='h2'>
                      CONTACT REGISTER
                  </Typography>
                  </Box>
                  <Box my={3}>
                    <RegisterForm handleRegister={handleRegister} />
                  </Box>
                </>
            }
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export { Register };
