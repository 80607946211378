import React from 'react';
import moment from 'moment-timezone';
import { List, ListItem, Grid, DialogContentText, Typography } from '@material-ui/core';

const AuditTrail = (props: any) => {

    let { row } = props

    const fields = [
        {
            name: 'createdAt',
            label: 'Date Created',
            render: <>{moment(row?.createdAt).tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A')}</>

        },
        {
            name: 'created_by',
            label: 'Created By',
            render:
                <Typography>
                    {row?.created_by_type?.toLowerCase() === 'employee' ?
                        'PacDocSign' :
                        `${row?.createdByContact?.Contact_First_Name || ''} ${row?.createdByContact?.Contact_Last_Name || ''}`
                    }
                </Typography>
        },
        {
            name: 'updatedAt',
            label: 'Date Last Modified',
            render: <>{moment(row?.updatedAt).tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A')}</>
        },
        {
            name: 'updated_by',
            label: 'Updated By',
            render:
                <Typography>
                    {row?.updated_by_type?.toLowerCase() === 'employee' ?
                        'PacDocSign' :
                        `${row?.updatedByContact?.Contact_First_Name || ''} ${row?.updatedByContact?.Contact_Last_Name || ''}`
                    }
                </Typography>
        },
        {
            name: 'match_activity_timestamp',
            label: 'Date Signing Agent Assigned',
            render: <>{row?.match_activity_timestamp ? moment(row?.match_activity_timestamp).tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A') : 'N/A'}</>
        },
        {
            name: '',
            label: 'Date Closed',
            render: <Typography>
                {row?.closed_cancelled_date ? moment(row?.closed_cancelled_date).tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A') : 'N/A'}
            </Typography>
        }
    ]

    return (
        <>
            <List>
                {fields.map(field => (
                    <ListItem>
                        <Grid container>
                            <Grid item xs={6}>
                                <DialogContentText>
                                    <b>{field?.label}</b>
                                </DialogContentText>
                            </Grid><Grid item xs={6}>
                                <DialogContentText>
                                    {field?.render || row?.[field?.name]}
                                </DialogContentText>
                            </Grid>
                        </Grid>
                    </ListItem>))}
            </List>
        </>
    );
}

export { AuditTrail };
