import { selector } from 'recoil';

import { verifyAuthToken } from './Auth'
import { division } from '../Atoms/Divisions';

import { api } from '../../Services/api';

const fetchDivisions = selector({
  key: 'fetchDivisions',
  get: async ({ get }) => {
    try {
      await verifyAuthToken()
      const res: any = await api.POST('client/byCompany');
      return {
        result: res,
        success: true
      };
    } catch(err) {
      return {
        result: [],
        success: false
      };
    }
  }
});

const fetchPrivilege = selector({
  key: 'fetchPrivilege',
  get: async ({ get }) => {
    const divisionId = get(division);
    if(divisionId) {
      try {
        const params = {
          divisionID: divisionId
        };
        await verifyAuthToken()
        const res: any = await api.POST('client/getPrivilege', params);
        return {
          result: res,
          success: true
        };
      } catch(err) {
        return {
          result: err,
          success: false
        };
      }
    }
    return {
      result: [],
      success: false
    }
  }
});

export { fetchDivisions, fetchPrivilege };
