import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { useStyles } from './Styles/Fields';

const Fields = ({ values, errors, touched, handleChange, handleBlur }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              label='Company Name'
              name='company_name'
              variant='outlined'
              size='small'
              fullWidth
              value={values.company_name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.company_name && errors.company_name}
              error={Boolean(touched.company_name && errors.company_name)}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          label='First Name'
          name='Contact_First_Name'
          variant='outlined'
          size='small'
          fullWidth
          value={values.Contact_First_Name}
          onChange={ e => { 
            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
            handleChange(e)}}
          onBlur={handleBlur}
          helperText={touched.Contact_First_Name && errors.Contact_First_Name}
          error={Boolean(touched.Contact_First_Name && errors.Contact_First_Name)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          label='Last Name'
          name='Contact_Last_Name'
          variant='outlined'
          size='small'
          fullWidth
          value={values.Contact_Last_Name}
          onChange={ e => { 
            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
            handleChange(e)}}
          onBlur={handleBlur}
          helperText={touched.Contact_Last_Name && errors.Contact_Last_Name}
          error={Boolean(touched.Contact_Last_Name && errors.Contact_Last_Name)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          label='Work Phone'
          name='Contact_Work_Phone'
          variant='outlined'
          size='small'
          fullWidth
          value={values.Contact_Work_Phone}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.Contact_Work_Phone && errors.Contact_Work_Phone}
          error={Boolean(touched.Contact_Work_Phone && errors.Contact_Work_Phone)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          label='Mobile Number'
          name='Contact_Cell'
          variant='outlined'
          size='small'
          fullWidth
          value={values.Contact_Cell}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.Contact_Cell && errors.Contact_Cell}
          error={Boolean(touched.Contact_Cell && errors.Contact_Cell)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          label='Email'
          name='Contact_Email'
          variant='outlined'
          size='small'
          fullWidth
          value={values.Contact_Email}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <FormControl className={classes.removeSelectMargin} fullWidth margin='dense' variant='outlined'>
          <InputLabel>Authentication Type</InputLabel>
          <Select
            label='Authentication Type'
            name='authenticationType'
            fullWidth
            value={values.authenticationType}
            onChange={handleChange}
            error={Boolean(touched.authenticationType && errors.authenticationType)}
          >
            <MenuItem value='password'>Password</MenuItem>
            <MenuItem value='two-factor'>Two Factor</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export { Fields };
