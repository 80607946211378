import React, { useEffect } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil'
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Card, CardContent, Container, Typography, LinearProgress } from '@material-ui/core';

import { Page } from '../Components/Page';

import { masterLogin } from './../Recoil/Selectors/Auth'
import { userInfo } from '../Recoil/Atoms/Login';

import { useStyles } from './Styles/Login';

const MasterLogin = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const setUser = useSetRecoilState(userInfo);

  const { token }: any = useParams()

  const login = useRecoilCallback(({ snapshot }) => async () => {
    const res = await snapshot.getPromise(masterLogin({ token }))
    if (res?.success) {
      setUser(res.result);
      navigate('/');
    } else {
      navigate('/login');
    }
  })

  useEffect(() => {
    login()
  }, [login])

  return (
    <Page className={classes.root} title='Client Register'>
      <Container className={classes.cardContainer} maxWidth='sm'>
        <Card>
            <CardContent className={classes.cardContent}>
                <div className={classes.currentMethodIcon}>
                <img alt='PacDocSign' src='/static/Logo.png' />
                </div>
                <>
                  <Box alignItems='center' display='flex' flexDirection='column'>
                      <Box width='100%'><LinearProgress /></Box>
                      <Typography align='center' variant='caption'>Please Wait...</Typography>
                  </Box>
                </>
            </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export { MasterLogin };
