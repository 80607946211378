import React from 'react';
import { Grid, Typography, FormControlLabel, Radio, FormHelperText, SvgIcon, Tooltip } from '@material-ui/core';
import { Info } from 'react-feather';
import { useStyles } from '../Styles/Fields'
const PartialSigning = ({ location, values, errors, handleChange, disabled }) => {
  const classes = useStyles()
  return (
    <>
      <Grid item md={4}>
        <Typography variant='body2' className={classes.pslabel}>Partial Signing
        <Tooltip
            title={
              <Typography variant='body2'>
                Partial Signing is considered when not full set of loan documents is being signed
              </Typography>
            }
          >
            <SvgIcon color='primary' className={classes.psToolTip}><Info ></Info></SvgIcon>
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item md={4}>
        <FormControlLabel
          label='Yes'
          control={
            <Radio
              name={`${location}.Partial_Signing`}
              value={'1'}
              checked={values.Partial_Signing === '1'}
              onChange={handleChange}
            />
          }
          disabled={disabled}
        />
      </Grid>
      <Grid item md={4}>
        <FormControlLabel
          label='No'
          control={
            <Radio
              name={`${location}.Partial_Signing`}
              value={'0'}
              checked={values.Partial_Signing === '0'}
              onChange={handleChange}
            />
          }
          disabled={disabled}
        />
      </Grid>
      { errors[location] && errors[location].Partial_Signing &&
        <Grid item xs={12}>
          <FormHelperText error>
            {errors[location].Partial_Signing}
          </FormHelperText>
        </Grid>
      }
    </>
  );
}

export { PartialSigning };
