import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { TextField, IconButton, InputAdornment, SvgIcon } from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { searchedOrders,  } from '../../Recoil/Atoms/Orders';

const Search = (props: any) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const searchQuery: any = useSetRecoilState(searchedOrders);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      searchQuery({
        field: '',
        value: ''
      });
    }
  }

  const handleSearch = (e) => {
    // e.preventDefault();

    const regDate = /^\d{4}-\d{2}-\d{2}$/;
    const regLastName = /^[a-zA-Z._]+$/i;

    if (regDate.test(searchValue)) {
      searchQuery({
        field: 'w_Appointment_Date',
        value: searchValue
      });
    } else if (regLastName.test(searchValue)) {
      searchQuery({
        field: 'w_Borrower_Last_Name',
        value: searchValue
      });
    } else {
      searchQuery({
        field: 'w_Loan_Number',
        value: searchValue,
      });
    }
    props.SearchStateChanger(false);
  };

  return (
    <TextField
      type='search'
      variant='outlined'
      label='Search Orders'
      margin='dense'
      value={searchValue}
      onChange={ (e) => {
        //  props.SearchStateChanger(true);
         handleChange(e);}}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={handleSearch}>
              <SvgIcon fontSize='small' color='action'>
                <SearchIcon onClick={() => handleSearch(searchValue)} />
              </SvgIcon>
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}

export { Search };
