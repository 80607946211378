import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Button, FormHelperText, TextField, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';

import { initialValues, passwordSchema } from '../../Common/Rules/LoginPassword';
import { marginStyles } from '../../Common/Styles/Margins';

interface HandlePassword {
  submitPassword: (params) => Promise<void>
};

const PasswordForm: FC<HandlePassword> = (props) => {
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);

  const handlePassword = async (values, { resetForm, setSubmitting }) => {
    await props.submitPassword({
      password: values.password,
      setSubmitting,
      resetForm,
      setAlert
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={passwordSchema}
      onSubmit={handlePassword}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        errors
      }) => (
        <form onSubmit={handleSubmit}>
          { alert &&
            <Box mb={3}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          }
          <TextField
            label='Password'
            name='password'
            type='password'
            variant='outlined'
            fullWidth
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            helperText={touched.password as Boolean && errors.password as String}
            error={Boolean(touched.password && errors.password)}
          />
          {errors.submit && (
            <FormHelperText error>
              {errors.submit as String}
            </FormHelperText>
          )}
          <Box mt={3}>
            <Button
              type='submit'
              size='large'
              color='primary'
              variant='contained'
              fullWidth
              disabled={isSubmitting}
            >
              {isSubmitting && <CircularProgress className={marginClasses.mr2} color='inherit' size={25} />}
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export { PasswordForm };
