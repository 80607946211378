import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValueLoadable, useSetRecoilState, useRecoilState } from 'recoil';

import { DashboardLayout } from '../Layout';
import { BasePage } from './BasePage';
import { Login } from '../Pages/Login';
import { ChangePassword } from '../Pages/ChangePassword';
import { ResetPassword } from '../Pages/ResetPassword';
import { MasterLogin } from '../Pages/MasterLogin';
import { RequestResetPassword } from '../Pages/RequestPasswordReset'
import { LoginPassword } from '../Pages/LoginPassword';
import { Register } from '../Pages/Register';
import { PageLoad } from '../Components/LoadingScreen/PageLoad';

import {
  checkAuthType,
  sendEmail,
  verifyEmail,
  loginWithPassword,
  loginWithoutPassword,
  refreshAuthToken
} from '../Recoil/Selectors/Auth';
import { twoFactorLogin, userInfo, forceLogin } from '../Recoil/Atoms/Login';

const RenderRoutes = () => {
  const [type, setType] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [refresh] = useState(true);
  const location = useLocation();
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(userInfo);
  const handleTwoFactorLogin = useSetRecoilState(twoFactorLogin);
  const firebaseUser = useRecoilValueLoadable(verifyEmail(location?.search));
  const mysqlUser = useRecoilValueLoadable(loginWithoutPassword);
  const refreshToken = useRecoilValueLoadable(refreshAuthToken);
  const setForceLoginState = useSetRecoilState(forceLogin);
  const forceUpdate = () => setForceLoginState(n => n + 1);

  const submitEmail = useRecoilCallback(({ snapshot }) => async ({ email, setSubmitting, resetForm, setAlert }) => {
    setAlert(null)
    forceUpdate()
    const response = await snapshot.getPromise(
      checkAuthType(email)
    );
    setType(null);
    if (response.success) {
      if (response.result.authenticationType === '1') {
        setAlert({
          status: 'error',
          message: 'User not found!'
        });
      }
      if (response.result.authenticationType === 'two-factor') {
        await snapshot.getPromise(sendEmail(email));
        setAlert({
          status: 'info',
          message: 'An email has been sent to this email address, please open the link in the email'
        });
      } else {
        setUserEmail(email);
      }
      resetForm();
      setSubmitting(false);
      setType(response.result.authenticationType);
    } else {
      setSubmitting(false);
      setAlert({
        status: 'error',
        message: 'Invalid Email'
      });
    }
  });

  const submitPassword = useRecoilCallback(
    ({ snapshot }) => async ({ password, setSubmitting, resetForm, setAlert }) => {
      const response = await snapshot.getPromise(
        loginWithPassword({
          email: userEmail,
          password
        })
      );
      setSubmitting(false);
      if (response.success) {
        resetForm();
        setUser(response.result);
        setType(null)
        navigate('/orders');
      } else {
        setAlert({
          status: 'error',
          message: 'Invalid Password'
        });
      }
    }
  );

  useEffect(() => {
    if (type === 'password') {
      navigate('/login-password');
    }
  }, [type, navigate]);

  useEffect(() => {
    if (firebaseUser.state === 'hasValue' && firebaseUser?.contents?.success) {
      handleTwoFactorLogin(1);
    }
  }, [firebaseUser, handleTwoFactorLogin]);

  useEffect(() => {
    if (mysqlUser.state !== 'loading' && mysqlUser.contents?.success) {
      setUser(mysqlUser.contents.result.user);
      navigate('/');
    }
  }, [mysqlUser, setUser, navigate]);

  useEffect(() => {
    if (!refreshToken) {
      setUser(null);
      window.location.reload()
    }
  }, [refreshToken, setUser]);

  useEffect(() => {
    if (refreshToken.contents && refreshToken.contents.success === false) {
      setUser(null);
      window.location.reload()
    }
  }, [refreshToken, setUser]);
  if ((((refreshToken.state === 'loading' && location?.pathname !== '/login' && location?.pathname !== '/login-password') && !refresh) ||
    ((firebaseUser.state === 'loading' || mysqlUser.state === 'loading') && location?.pathname === '/login')) && !refresh) {
    return <PageLoad />
  }
  return (
    <Routes>
      <Route path="*" element={
        user?.user?.isTempPass === '1' || user?.isTempPass === '1' ?
          <DashboardLayout>
            <ChangePassword />
          </DashboardLayout>
          :
          user ?
            <DashboardLayout>
              <BasePage />
            </DashboardLayout>
            :
            <Routes>
              <Route
                path='/login'
                element={<Login submitEmail={submitEmail} />}
              />
              <Route
                path='/register'
                element={<Register />}
              />
              <Route
                path='/resetpassword'
                element={<RequestResetPassword />}
              />
              <Route
                path='/reset-password/:token'
                element={<ResetPassword />}
              />
              <Route
                path='/master-login/:token'
                element={<MasterLogin />}
              />
              {!user && type === 'password' &&
                <Route
                  path='/login-password'
                  element={<LoginPassword submitPassword={submitPassword} />}
                />
              }
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
      } />

    </Routes>
  )
};

export default (RenderRoutes);
