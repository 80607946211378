import React from 'react';
import { Typography, Box, Divider } from '@material-ui/core';
import { useRecoilValueLoadable } from 'recoil';

import { Page } from '../Components/Page';
import { getProfile } from '../Recoil/Selectors/Auth';
import { ProfileForm } from '../Components/MyProfile/ProfileForm';

import { useStyles } from './Styles/Page';

const MyProfile = () => {
  const classes = useStyles();

  const profile: any = useRecoilValueLoadable(getProfile)
  const user = profile?.contents?.result || {}

  return (
    <Page className={classes.root} title='Update Profile'>
      <Typography variant='h3'>Update Profile</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <ProfileForm loading = { profile.state === 'loading' } user = { user } />
    </Page>
  );
}

export { MyProfile };
