export const updateAdditionalBorrowers = (updated, initial) => {
  const iABM = [...initial.additionalBorrowersModel];
  const uABM = [...updated.location_one.additionalBorrowersModel];

  if (iABM.length && iABM.length === uABM.length) {
    uABM.map((uB) => ({
      ...uB,
      remove: ''
    }));
  } else if (iABM.length > 0 && uABM.length === 0) {
    iABM.forEach((iB) => {
      uABM.push({
        ...iB,
        remove: 'true'
      });
    })
  } else if (uABM.length && uABM.length < iABM.length) {
    iABM.forEach((iB, i) => {
      if (uABM[i] && uABM[i].id) {
        uABM[i].remove = '';
      } else if (!uABM[i]) {
        uABM.push({
          ...iB,
          remove: 'true'
        });
      }
    })
  } else if (uABM.length && uABM.length > iABM.length) {
    uABM.map((uB) => {
      if (uB.id) {
        uB.remove = ''
      }

      return uB;
    })
  }

  return uABM;
};
