import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    gridRoot: {
      flexGrow: 1
    },
    pRelative: {
      position: 'relative'
    },
    closeButton: {
      position: 'absolute',
      top: '-2px',
      right: '-2px',
      cursor: 'pointer',
      background: theme.palette.background.default
    },
    splitCheckbox: {
      marginBottom: 10,
    },
    pd5: {
      padding: 5
    },
    m10: {
      margin: 10
    },
    mt10: {
      marginTop: 10
    },
    mb20: {
      marginBottom: 20
    },
    addressItem: {
      color: '#000',
      padding: '7px 8px',
      cursor: 'pointer',
      '&:hover': {
        background: '#e7e7e7'
      }
    },
    removeSelectMargin: {
      marginTop: 0,
      marginBottom: 0
    },
    textArea: {
      resize: 'vertical',
    },
    viewModeClass: {
      '& .Mui-disabled': {
        color: 'black'
      }
    },
    psToolTip: {
      position: 'relative',
      top: '8px',
      left: '5px',
    },
    pslabel: {
      bottom: '5px',
      position: 'relative'
    },
    errorIndicator: {
      color: '#f44336'
    }
  })
);

export { useStyles };
