import React from 'react';
import { Box, Card, CardContent, Container, Typography } from '@material-ui/core';

import { Page } from '../Components/Page';
import { RequestResetPasswordForm } from '../Components/RequestResetPassword/RequestResetPasswordForm';

import { useStyles } from './Styles/Login';

const RequestResetPassword = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title='Client Register'>
      <Container className={classes.cardContainer} maxWidth='sm'>
        <Card>
          <CardContent className={classes.cardContent}>
            <div className={classes.currentMethodIcon}>
              <img alt='LOGO' src='/static/Logo.png' />
            </div>
              <>
                <Box alignItems='center' display='flex' justifyContent='space-between'>
                  <Typography color='textPrimary' gutterBottom variant='h2'>
                    RESET PASSWORD
                  </Typography>
                </Box>
                <Box my={3}>
                  <RequestResetPasswordForm />
                </Box>
              </>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export { RequestResetPassword };
