import type { Theme } from '../../ThemeComponents/theme';
import { makeStyles } from '@material-ui/core';

export const displayStyles = makeStyles((theme: Theme) => ({
  w100: {
    width: '100%'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexRowC: {
    display: 'flex',
    alignItems: 'center',
  },
  flexRowE: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  flexRowCC: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexRowCE: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  flexRowEC: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center'
  }
}));
