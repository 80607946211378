import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const RegisterText = () => {
  return (
    <>
      <Typography variant='body1'>Thank you for registering with us.</Typography>
      <br></br>
      <Typography variant='body1'>
        <Link to='/login'>Login Now</Link>
      </Typography>
      <br></br>
      <br></br>
      <Typography variant='body1'>Thank you,</Typography>
      <br></br>
      <Typography variant='body1'>PacDocSign Team</Typography>
    </>
  );
}

export { RegisterText };
