import React from 'react'
import { DialogContentText, ListItem, Grid } from '@material-ui/core';
import { GeneralModal } from '../Modal';

export function SignerInfo({ signer = {
  Signer_First: '', Signer_Last: '', Signer_Email: ''
}, close }) {
  const signerFields = [
    { name: 'w_Loan_Number', label: 'Loan #' },
    { name: 'signer_id', label: 'Signer ID' },
    {
      name: 'Signer_First',
      label: 'Signer Name',
      render: <> {signer?.Signer_First} {signer?.Signer_Last}</>
    },
    { name: 'city', label: 'Signer City' },
    { name: 'state', label: 'Signer State' },
    { name: 'zip', label: 'Signer Zip' },
    { name: 'mobile_phone', label: 'Signer Mobile Phone' },
    {
      name: 'w_Loan_Number',
      label: 'Signer Email',
      render: <><a href={`mailto:${signer?.Signer_Email}`}>{signer?.Signer_Email}</a></>
    },
    { name: 'send_receive_sms', label: 'Registered SMS' },
  ]
  return (
    <GeneralModal
      title='Signer Info'
      open={signer}
      openModal={e => close(null)}
      noSubmit
    >
      {signerFields.map((f, index) => (
        <ListItem key={index}>
          <Grid container>
            <Grid item xs={6}>
              <DialogContentText>
                <b>{f.label}</b>
              </DialogContentText>
            </Grid><Grid item xs={6}>
              <DialogContentText>
                {f.render || signer?.[f.name]}
              </DialogContentText>
            </Grid>
          </Grid>
        </ListItem>))}
    </GeneralModal>
  )
}
