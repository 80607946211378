import React from 'react';
import { Typography, Box, Divider, SvgIcon } from '@material-ui/core';
import { Phone, Clock } from 'react-feather';

import { Page } from '../Components/Page';

import { useStyles } from './Styles/Page';
import { useHOStyles } from './Styles/HoursOfOperation';

const HoursOfOperations = () => {
  const classes = useStyles();
  const hoClasses = useHOStyles();

  return (
    <Page className={classes.root} title='Hours Of Operation'>
      <Typography variant='h3'>HOURS OF OPERATION</Typography>
      <Box my={2}>
        <Divider variant='fullWidth' />
      </Box>
      <Typography variant='h4'>OFFICE HOURS</Typography>
      <Box my={2}>
        <Typography variant='h5'>Monday - Friday</Typography>
        <Typography className={hoClasses.textWithIcon} variant='body2'>
          <SvgIcon className={hoClasses.icon} fontSize='small' color='primary'>
            <Clock />
          </SvgIcon>
           7:00am - 7:30pm PST
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant='h5'>Saturday</Typography>
        <Typography className={hoClasses.textWithIcon} variant='body2'>
          <SvgIcon className={hoClasses.icon} fontSize='small' color='primary'>
            <Clock />
          </SvgIcon>
          8:00am - 4:00pm PST
        </Typography>
      </Box>
      <Box my={2}>
        <Typography variant='h5'>After Hours Tel</Typography>
        <Typography className={hoClasses.textWithIcon} variant='body2'>
          <SvgIcon className={hoClasses.icon} fontSize='small' color='primary'>
            <Phone />
          </SvgIcon>
          (310)-597-5511
        </Typography>
      </Box>
    </Page>
  );
}

export { HoursOfOperations };
