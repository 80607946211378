import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';

import { Fields } from './Fields';

import {
  findAuthorizedDomains,
  signup,
  sendEmailToRegister,
  firebaseSignup
} from '../../Recoil/Selectors/Register';

import { initialValues, validationSchema } from '../../Common/Rules/RegisterForm';
import { marginStyles } from '../../Common/Styles/Margins';

const RegisterForm = ({ handleRegister }) => {
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);

  const onSubmit = useRecoilCallback(({snapshot}) => async (values: any) => {
    let res = null;
    let method = '';
    const firebaseRes: any = await snapshot.getPromise(firebaseSignup(values));

    const domainsRes: any = await snapshot.getPromise(findAuthorizedDomains(values));
    if (domainsRes.result.authorized_domains) {
      const authorizedDomains = domainsRes.result.authorized_domains.split(', ');
      const emailDomain = values.Contact_Email.split('@')[1];
      const find = authorizedDomains.find(e => e === emailDomain);
      values.user = values.Contact_Email;
      if (find) {
        values.Company_ID = domainsRes.result.clientID;
        res = await snapshot.getPromise(signup(values));
        method = 'signup';
      } else {
        if (firebaseRes.success) {
          res = await snapshot.getPromise(sendEmailToRegister(values));
          method = 'email';
        }
      }
    } else {
      if (firebaseRes.success) {
        res = await snapshot.getPromise(sendEmailToRegister(values));
        method = 'email';
      }
    }
    if ((res && !res.success) || !firebaseRes.success) {
      setAlert({
        status: 'error',
        message: res ? res.result.message : firebaseRes.result.message
      });
    } else {
      handleRegister(method);
    }
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <Fields
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          { alert &&
            <Box my={2}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          }
          <Box display='flex' justifyContent='flex-end' mt={3}>
            <Link to='/login'>
              <Button className={marginClasses.mr1} color='primary' size='small'>
                Cancel
              </Button>
            </Link>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              size='small'
              disabled={isSubmitting}
            >
              {isSubmitting && <CircularProgress className={marginClasses.mr2} color='inherit' size={25} />}
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export { RegisterForm };
