import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyles } from '../Styles/Fields';


const SpecialInstruction = ({ location, values, handleChange, disabled }) => {
  const classes = useStyles()
  return (
    <>
      <Grid container item xs={12} sm={12} md={12}>
        <TextField
          multiline
          label='Special Instructions'
          variant='outlined'
          fullWidth
          name={`${location}.w_Additional_Information`}
          rows={1}
          value={values.w_Additional_Information}
          onChange={handleChange}
          disabled={disabled}
          inputProps={{ className: classes.textArea }}
        />
      </Grid>
    </>
  );
}

export { SpecialInstruction };
