import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, CircularProgress } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { marginStyles } from '../../Common/Styles/Margins';
import { useStyles } from './Styles';

const GeneralModal = (props) => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const { open, title, loading, openModal, noSubmit, submitLabel, fullScreen = false, maxWidth } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth={title === 'Cancel Order' ? 'sm' : maxWidth || 'md'}
      fullWidth={true}
    >
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant='h4'>{title}</Typography>
      </MuiDialogTitle>
      <DialogContent className={classes.dialogContent}>
        {props.children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color='secondary' onClick={() => openModal('', false)} disabled={loading}>
          {title === 'Cancel Order' ? 'No' : 'Cancel'}
        </Button>
        {!noSubmit &&
          <Button color='primary' variant='contained' type='submit' onClick={props.handleSubmit} disabled={loading}>
            {loading && <CircularProgress className={marginClasses.mr2} color='inherit' size={25} />}
            {title === 'Cancel Order' ? 'Yes' : submitLabel || 'Submit'}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

export { GeneralModal };

