import React from 'react';
import { useRecoilValue } from 'recoil';
import { Formik, Form } from 'formik';

import { OrdersForm } from './OrderForm';
import { SingleLoanNumber } from './Fields/SingleLoanNumber';

import { currentOrder } from '../../Recoil/Atoms/Orders';
import { useStyles } from './Styles/Fields';

const ViewOrder = () => {
  const order: any = useRecoilValue(currentOrder);
  const classes = useStyles()
  return (
    <Formik
      initialValues={{
        w_Loan_Number: order.w_Loan_Number,
        location_one: order
      }}
      onSubmit={null}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue
      }) => {
        return (
          <Form className={classes.viewModeClass}>
            <SingleLoanNumber
              value={values.w_Loan_Number}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              disabled={true}
            />
            <OrdersForm
              location='location_one'
              values={{ ...values.location_one }}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              disabled={true}
            />
          </Form>
        )
      }}
    </Formik>
  );
};

export { ViewOrder };
