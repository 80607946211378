import React, { useRef } from 'react';
import { Box, InputLabel } from '@material-ui/core';
import NotchedOutline from '@material-ui/core/OutlinedInput/NotchedOutline';

import { useStyles } from './Styles';

const OutlinedSection = (props) => {
  const classes = useStyles();
  const { title, children } = props;
  const labelRef = useRef<HTMLLabelElement>(null);

  return (
    <Box className={classes.container} aria-label='outline-container'>
      <InputLabel
        ref={labelRef}
        aria-label='outline-label'
        variant='outlined'
        className={classes.inputLabel}
        shrink
      >
        {title}
      </InputLabel>
      <Box aria-label='outline-body' className={classes.root}>
        <Box aria-label='outline-body-content' className={classes.content}>
          {children}
          <NotchedOutline
            notched
            className={classes.notchedOutline}
            labelWidth={labelRef.current?.offsetWidth ?? 0}
          />
        </Box>
      </Box>
    </Box>
  );
}

export { OutlinedSection };
