import type { Theme } from '../../ThemeComponents/theme';
import { makeStyles } from '@material-ui/core';

export const paddingStyles = makeStyles((theme: Theme) => ({
  pr1: {
    paddingRight: 5
  },
  pr2: {
    paddingRight: 10
  },
  pr3: {
    paddingRight: 15
  },
  pr4: {
    paddingRight: 20
  },
  pr5: {
    paddingRight: 30
  },
  pl1: {
    paddingLeft: 5
  },
  pl2: {
    paddingLeft: 10
  },
  pl3: {
    paddingLeft: 15
  },
  pl4: {
    paddingLeft: 20
  },
  pl5: {
    paddingLeft: 30
  },
  pt1: {
    paddingTop: 5
  },
  pt2: {
    paddingTop: 10
  },
  pt3: {
    paddingTop: 15
  },
  pt4: {
    paddingTop: 20
  },
  pt5: {
    paddingTop: 30
  },
  pb1: {
    paddingBottom: 5
  },
  pb2: {
    paddingBottom: 10
  },
  pb3: {
    paddingBottom: 15
  },
  pb4: {
    paddingBottom: 20
  },
  pb5: {
    paddingBottom: 30
  },
}));
