import React, { Fragment } from 'react';
import { Grid, TextField, Button, SvgIcon } from '@material-ui/core';
import { FieldArray } from 'formik';
import { Plus, XCircle } from 'react-feather';

const AdditionalBorrowers = ({ location, values, handleChange, setFieldValue, disabled }) => {
  return (
    <>
      { !disabled &&
        <Grid item xs={4}>
          <Button
            color='primary'
            variant='contained'
            onClick={
              () => setFieldValue(`${location}.additionalBorrowersModel[${values.additionalBorrowersModel.length}]`, {
                first_name: '',
                last_name: ''
              })
            }
          >
            <SvgIcon fontSize='small'><Plus /></SvgIcon> Additional Borrower
          </Button>
        </Grid>
      }
      <FieldArray
        name={`${location}.additionalBorrowersModel`}
        render={(helper) => (
          values.additionalBorrowersModel.map((val, i) =>
            <Fragment key={`${location}-additionalBorrowersModel-${i}`}>
              <Grid item md={4}>
                <TextField
                  label="Additional Borrower's First Name"
                  name={`${location}.additionalBorrowersModel[${i}].first_name`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  margin='none'
                  value={val.first_name}
                  onChange={ e => { 
                    e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
                    handleChange(e)}}
                  disabled={disabled}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Additional Borrower's Last Name"
                  name={`${location}.additionalBorrowersModel[${i}].last_name`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  margin='none'
                  value={val.last_name}
                  onChange={ e => { 
                    e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
                    handleChange(e)}}
                  disabled={disabled}
                />
              </Grid>
              {!disabled &&
                <Grid item md={4}>
                  <SvgIcon
                    fontSize='small'
                    color='primary'
                    onClick={() => helper.remove(i)}
                  >
                    <XCircle />
                  </SvgIcon>
                </Grid>
              }
            </Fragment>
          )
        )}
      />
    </>
  );
}

export { AdditionalBorrowers };
