import React, { useState } from 'react';
import { useSetRecoilState, useRecoilCallback } from 'recoil';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik, Form } from 'formik';

import { Toast } from '../Toasts';
import { Fields } from './Fields';
import { validationSchema } from '../../Common/Rules/ProfileForm';
import { PageLoad } from '../../Components/LoadingScreen/PageLoad';

import { updateUser } from '../../Recoil/Atoms/Login';
import { updateProfile } from '../../Recoil/Selectors/Auth';

import { marginStyles } from '../../Common/Styles/Margins';

const ProfileForm = (props: any) => {
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });
  const todoUpdates = useSetRecoilState(updateUser);
  const forceUpdate = () => todoUpdates((n) => n + 1);

  const { loading, user } = props

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { setSubmitting }) => {
    setSubmitting(true);
    const res = await snapshot.getPromise(updateProfile(values));
    setSubmitting(false);
    if (res.success) {
      forceUpdate()
      setToast({
        message: 'Profile Updated Successfully!',
        type: 'success',
        open: true
      });
    } else {
      setAlert({
        status: 'error',
        message: res.result.data.message
      });
    }
  });

  return (
    loading 
    ?
      <PageLoad />
    :
    <>
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
      <Formik
        initialValues={user}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        }: any) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Fields
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              { alert &&
                <Box mt={3}>
                  <Alert severity={alert.status}>{alert.message}</Alert>
                </Box>
              }
              <Box mt={3} display='flex' justifyContent='flex-end'>
                <Button
                  variant='contained'
                  size='large'
                  color='primary'
                  type='submit'
                  disabled={isSubmitting}
                >
                  {isSubmitting && <CircularProgress className={marginClasses.mr2} color='inherit' size={25} />}
                  Submit
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export { ProfileForm };
