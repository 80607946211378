import React, { useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { Typography, Grid, Card, CardContent, CircularProgress, SvgIcon, Tooltip, Box, Button } from '@material-ui/core';
import { UploadCloud, AlertTriangle } from 'react-feather';
import { useDropzone } from 'react-dropzone';

import { revisedDocumentAlert } from '../../Recoil/Selectors/Orders';
import { currentOrder } from '../../Recoil/Atoms/Orders';
import { GeneralModal } from '../Modal';


import { useStyles } from './Style/Style';
import { displayStyles } from '../../Common/Styles/Display';


const Filedropzone = ({ uploadRequest, Id, files, disabled = false, mergedFiles }) => {
  const classes = useStyles();
  const displayClasses = displayStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState([])
  const [docsLocked, setDocsLocked] = useState(false)

  const selectedOrder: any = useRecoilValue(currentOrder);

  const sendDocumentAlert = useRecoilCallback(({ snapshot }) => async () => {
    if (selectedOrder?.documents_locked === 'Y') {
      await snapshot.getPromise(revisedDocumentAlert(selectedOrder));
    } 
    // else {
    //   await snapshot.getPromise(uploadDocumentAlert({ ...selectedOrder, files: acceptedFiles }));
    // }
  })

  const cancelFile = (file: any) => {
    const fileArray = [...selectedFiles]
    const index = selectedFiles.findIndex( (selectedFile) => selectedFile.path === file.path )
    fileArray.splice(index, 1);
    setSelectedFiles(fileArray);
  }

  const uploadFile = async (file: any) => {
    const fileArray = [...selectedFiles]
    const index = selectedFiles.findIndex( (selectedFile) => selectedFile.path === file.path )
    setUploading([...uploading, file])
    const res = await uploadRequest(file);
    file.existing = false
    fileArray[index].finished = true
    fileArray[index].hasError = !res.success
    setSelectedFiles(fileArray);
    sendDocumentAlert()
  }

  const uploadMultipleFiles = async (acceptedFiles) => {
    setSelectedFiles(acceptedFiles)
    const uploadedFiles = []
    setUploading([])
    for await ( let [index, file] of acceptedFiles.entries() ) {
     
      if (file.name.split('.').pop().toLowerCase() !== 'pdf'){
        acceptedFiles[index].finished = true
        acceptedFiles[index].pdf = false
        acceptedFiles[index].hasError = true
        continue
      }
     
      const existingFiles = files.filter( (existingFile) => existingFile.name === file.name )
      if (existingFiles.length > 0){
        acceptedFiles[index].existing = true
        acceptedFiles[index].index = index
        acceptedFiles[index].pdf = true
        setSelectedFiles(acceptedFiles);
        uploadedFiles.push(file)
      } else {
        const res = await uploadRequest(file);
        acceptedFiles[index].finished = true
        acceptedFiles[index].hasError = !res.success
        acceptedFiles[index].pdf = true
        setSelectedFiles(acceptedFiles);
        uploadedFiles.push(file)
      }
    }
    if (uploadedFiles.length > 0) {
      sendDocumentAlert()
    }
  }

  const onDrop = async (acceptedFiles) => {
    if (disabled)
      return
    if (selectedOrder?.documents_locked === 'Y') {
      setDocsLocked(true)
      setSelectedFiles([ ...acceptedFiles ])
    } else {
      await uploadMultipleFiles([ ...acceptedFiles ])
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <GeneralModal 
        title='Documents Locked'
        submitLabel='OK'
        handleSubmit = { async () => { 
          await uploadMultipleFiles([ ...selectedFiles ]) 
          setDocsLocked(false) 
        }}
        open={docsLocked}
        openModal={ () => { setDocsLocked(false); setSelectedFiles([]) }}
        maxWidth='sm' 
      >
        <Typography variant='body2'>
          Documents are locked are you sure you want to upload new documents?
        </Typography>
      </GeneralModal>
      <Grid
        className={classes.fileDropzoneWrapper}
        direction='column'
        container
        {...getRootProps()}
      >
        <input className={classes.fileDropInput} disabled={disabled} {...getInputProps()} />
        { isDragActive ?
          <Typography align='center'>Drop the file here ...</Typography> :
          <>
            <SvgIcon fontSize='large' color='primary'>
              <UploadCloud />
            </SvgIcon>
            <Typography variant='h5' color='primary'>Browse Computer</Typography>
            <Typography variant='h4'>Drop Files here to upload.</Typography>
          </>
        }
      </Grid>

      <div className={classes.selectedFilesWrapper}>
      {
        selectedFiles.map((file) => {
          return (
            <Card className={classes.fileCard}>
              <CardContent className={`${classes.cardContent} ${displayClasses.flexRowCC}`}>
                {
                  file.existing ?
                  <Box padding={0} display='flex' alignItems='center'flexDirection='column'>
                    <AlertTriangle />
                    <Typography variant='body2'>{file.path}</Typography>
                    <Typography variant='caption' color='error'>
                      File With This Name Already Exists!
                    </Typography>
                    <Box display='flex' alignItems='center'flexDirection='column'>
                      <Button
                        disabled={ uploading.filter( (uploadingFile) => uploadingFile.path === file.path ).length > 0 }
                        variant='outlined'
                        className={classes.marginBt}
                        onClick={ async () => await uploadFile(file) }
                      >
                        { uploading.filter( (uploadingFile) => uploadingFile.path === file.path ).length > 0 && <CircularProgress size={12} />}  Replace
                        </Button>
                      <Button 
                        disabled={ uploading.filter( (uploadingFile) => uploadingFile.path === file.path ).length > 0 }
                        onClick={ ()=> cancelFile(file) } 
                        variant='outlined' 
                        fullWidth
                        >
                          Cancel
                        </Button>
                    </Box>
                  </Box>
                  :
                  !file.finished 
                  ?
                  <Box padding={2} display='flex' alignItems='center'flexDirection='column'>
                    <Typography variant='body2'>{file.path}</Typography>
                    <CircularProgress size={12} />
                  </Box>
                  :
                  <Tooltip title={file.path}>
                    <Typography variant='body2' color={file.hasError ? 'error' : undefined}>
                      {file.path}
                      {!file.pdf && <Typography variant='body2' color='error'>File format not supported. Please convert the file to pdf and retry.</Typography>}
                    </Typography>
                  </Tooltip>
                }
              </CardContent>
            </Card>
          )
        })
        }
      </div>
    </>
  );
}

export { Filedropzone };
