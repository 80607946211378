import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    content: {
      padding: '18.5px 14px',
      marginBottom: 16,
    },
    inputLabel: {
      position: 'absolute',
      left: 0,
      top: 0,
      padding: '0 5px',
      transform: 'translate(0, 24px) scale(1)',
      background: theme.palette.background.default
    },
    container: {
      position: 'relative',
      marginTop: '8px',
    },
    notchedOutline: {
      borderRadius: 4,
      borderColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)',
    },
  })
);

export { useStyles };
