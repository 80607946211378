import { atom } from 'recoil';

const division = atom({
  key: 'division',
  default: 0
});

const userPrivilege = atom({
  key: 'userPrivilege',
  default: ''
});

export { division, userPrivilege };
