import { selector, selectorFamily } from 'recoil';
import moment from 'moment';

import { verifyAuthToken } from './Auth'
import { pagination, refetchOrders, forceUpdateFiles, searchedOrders, forceRefetchOrders, refetchConfirmation, forceDownload, refetchGetOrderById, resendMailToPacdoc } from '../Atoms/Orders';
import { division, userPrivilege } from '../Atoms/Divisions';
import { userInfo } from '../Atoms/Login';

import { api, fileapi } from '../../Services/api';

const fetchOrders = selector({
  key: 'fetchOrders',
  get: async ({ get }) => {
    get(refetchOrders)
    const divisionId = get(division);
    const privilege = get(userPrivilege);
    const { limit, page, orderBy, orderType } = get(pagination);
    const search = get(searchedOrders);
    get(forceRefetchOrders)

    if (divisionId && privilege) {
      const date = moment().subtract(90, 'days').format('YYYY-MM-DD');
      try {
        const params: any = {
          limit,
          page,
          date,
          division_id: divisionId,
          orderBy,
          orderType
        };
        params.searchQuery = search.value;
        await verifyAuthToken()
        const res: any = await api.POST('orders/byDivision', params);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }

    return {
      result: [],
      success: false
    }
  }
});

const createOrder = selectorFamily({
  key: 'createOrder',
  get: (params: any) => async ({ get }) => {
    const divisionId = get(division);
    if (params) {
      // delete params.location_one.Closing_Type
      params.location_one.division_id = divisionId;
      params.location_one.w_record_state = 'New';
      params.location_one.w_creation_date = moment().format('MM/DD/YYYY');
      params.location_one.w_creation_time = moment().format('hh:mm a');
      if (params.location_two) {
        // delete params.location_two.Closing_Type
        params.location_two.division_id = divisionId;
        params.location_two.w_record_state = 'New';
        params.location_two.w_creation_date = moment().format('MM/DD/YYYY');
        params.location_two.w_creation_time = moment().format('hh:mm a');
      }
      try {
        await verifyAuthToken()
        const res: any = await api.POST('orders/', params);
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const editOrder = selectorFamily({
  key: 'editOrder',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT('orders/', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const sendMailToPacdoc = selectorFamily({
  key: 'sendMailToPacdoc',
  get: (params: any) => async ({ get }) => {
    try {
      get(resendMailToPacdoc)
      await verifyAuthToken()
      const res: any = await api.POST('orders/send-mail-to-pacdoc', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const uploadFile = selectorFamily({
  key: 'uploadFile',
  get: ({ file, destination, }: any) => async ({ get }) => {
    try {
      get(forceUpdateFiles);
      if (file) {
        const formData: any = new FormData();
        formData.append('file', file);
        formData.append('destination', String(destination));
        formData.append('orderid', String(destination));
        await verifyAuthToken()
        const res: any = await fileapi.POST('upload', formData);
        return {
          result: res,
          success: true
        };
      }
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }

    return {
      result: false,
      success: false
    }
  }
});

const uploadDocumentAlert = selectorFamily({
  key: 'uploadDocumentAlert',
  get: (order: any) => async ({ get }) => {
    try {
      const user = get(userInfo);

      await verifyAuthToken()
      const res: any = await api.POST('client/uploadDocumentAlert', {
        client_name: user.Contact_Email,
        order_number: order.f_loan_number,
        Id: order.Id,
        last_name: order.f_borrower_last_name,
        date: order.f_appointment_date,
        time: order.f_appointment_time,
        files: order.files
      });
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const revisedDocumentAlert = selectorFamily({
  key: 'revisedDocumentAlert',
  get: (order: any) => async ({ get }) => {
    try {
      const user = get(userInfo);

      await verifyAuthToken()
      const res: any = await api.POST('client/revisedDocumentAlert', {
        company_name: user.user.company_name,
        f_loan_number: order.f_loan_number,
        f_borrower_last_name: order.f_borrower_last_name,
        date: order.f_appointment_date,
        time: order.f_appointment_time
      });
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const FetchFileList = selectorFamily({
  key: 'FetchFileList',
  get: (order: any) => async ({ get }) => {
    if (order.Id) {
      try {
        get(forceUpdateFiles)
        await verifyAuthToken()
        const res: any = await fileapi.POST('list', {
          folder: `borrower/${order.Id}`
        });
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
});

const FetchMerged = selectorFamily({
  key: 'FetchMerged',
  get: (order: any) => async () => {
    if (order.Id) {
      try {
        await verifyAuthToken()
        const res: any = await fileapi.POST('list', {
          folder: `borrower/${order.Id}/signers`
        });
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: [],
          success: false
        };
      }
    }
  }
});

const fetchScanbacks = selectorFamily({
  key: 'fetchScanbacks',
  get: (order: any) => async ({ get }) => {
    if (order.Id) {
      try {
        get(forceDownload)
        await verifyAuthToken()
        const res: any = await fileapi.POST('list', {
          folder: `borrower/${order.Id}/signers/scanbacks`
        });
        return {
          result: res,
          success: true
        };
      } catch (error) {
        return {
          result: error,
          success: false
        };
      }
    }
  }
});

const DeleteFile = selectorFamily({
  key: 'DeleteFile',
  get: (params) => async ({ get }) => {
    try {
      get(forceUpdateFiles)
      await verifyAuthToken()
      const res: any = await fileapi.POST('delete', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const FetchFile = selectorFamily({
  key: 'FetchFile',
  get: (params: any) => async ({ get }) => {
    try {
      get(forceDownload)
      await verifyAuthToken()
      const res: any = await fileapi.DOWNLOAD('getfile', params);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

const cancelOrder = selectorFamily({
  key: 'cancelOrder',
  get: (params: any) => async () => {
    try {
      await verifyAuthToken()
      const res: any = await api.PUT(`/orders/cancel/${params.Id}`,);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});


const viewClientConfirmation = selectorFamily({
  key: 'viewClientConfirmation',
  get: (params: any) => async ({ get }) => {
    get(refetchConfirmation)
    if (params.Id) {
      try {
        await verifyAuthToken()
        const res: any = await api.GET_DOWNLOAD(`orders/view/clientconfirmation/${params.Id}`);
        return {
          result: res,
          success: true
        };
      } catch (err) {
        return {
          result: err,
          success: false
        };
      }
    }
  }
});


const getOrderById = selectorFamily({
  key: 'getOrderById',
  get: (params: any) => async ({ get }) => {
    try {
      const { Id, division_id } = params
      get(refetchGetOrderById)
      await verifyAuthToken()
      const res: any = await api.GET(`/orders/getClientOrderbyid/${Id}/${division_id}`);
      return {
        result: res,
        success: true
      };
    } catch (error) {
      return {
        result: error,
        success: false
      };
    }
  }
});

export {
  fetchOrders,
  createOrder,
  editOrder,
  sendMailToPacdoc,
  uploadFile,
  uploadDocumentAlert,
  revisedDocumentAlert,
  FetchFileList,
  FetchMerged,
  fetchScanbacks,
  DeleteFile,
  FetchFile,
  cancelOrder,
  viewClientConfirmation,
  getOrderById
};
