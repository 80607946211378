import { selectorFamily } from 'recoil';

import { api } from '../../Services/api';
import { createUserWithEmailAndPassword } from '../../Services/firebase';

const findAuthorizedDomains = selectorFamily({
  key: 'findAuthorizedDomains',
  get: (values: any) => async () => {
    try {
      const res: any = await api.POST('auth/client/findAuthorizedDomains', values);
      return {
        result: res,
        success: true
      };
    } catch(err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const signup = selectorFamily({
  key: 'signup',
  get: (values: any) => async () => {
    try {
      const res: any = await api.POST('auth/client/signup', values);
      return {
        result: res,
        success: true
      };
    } catch(err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const sendEmailToRegister = selectorFamily({
  key: 'sendEmailToRegister',
  get: (values: any) => async () => {
    try {
      const res: any = await api.POST('auth/client/sendEmailToRegister', values);
      return {
        result: res,
        success: true
      };
    } catch(err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

const firebaseSignup = selectorFamily({
  key: 'firebaseSignup',
  get: (params: any) => async () => {
    try {
      await createUserWithEmailAndPassword(params);
      return {
        success: true
      };
    } catch(err) {
      return {
        result: err,
        success: false
      };
    }
  }
});

export {
  findAuthorizedDomains,
  signup,
  sendEmailToRegister,
  firebaseSignup
};
