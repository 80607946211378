import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checklistMargin: {
        margin: '5px 2px'
    },
    smFont: {
        fontSize: '14px'
    },
    valid: {
        color: '#4BCA81'
    },
    invalid: {
        color: '#FF0033'
    }
  })
);
