import React from 'react';
import { Grid } from '@material-ui/core';

import { AppointmentDateTime } from './Fields/AppointmentDateTime';
import { BorrowerName } from './Fields/BorrowerName';
import { CoBorrowerName } from './Fields/CoBorrowerName';
import { AdditionalBorrowers } from './Fields/AdditionalBorrowers';
import { SignerType } from './Fields/SignerType';
import { PartialSigning } from './Fields/PartialSigning';
// import { AdditionalClientEmail } from './Fields/AdditionalClientEmail';
import { SignerAddress } from './Fields/SignerAddress';
import { PropertyLocation } from './Fields/PropertyLocation';
import { SignerPhone } from './Fields/SignerPhone';
import { BorrowerEmail } from './Fields/BorrowerEmail';
import { SpecialInstruction } from './Fields/SpecialInstruction';

import { useStyles } from './Styles/Fields';
import { OutlinedSection } from '../Section';

const OrdersForm = ({ location, values, errors, touched, handleChange, handleBlur, setFieldValue, disabled }) => {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.gridRoot} container spacing={2} alignItems='center'>
        <AppointmentDateTime
          location={location}
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          disabled={disabled}
        />
      </Grid>
      <OutlinedSection title='Borrower Information'>
        <Grid className={classes.gridRoot} container spacing={2}>

          <BorrowerName
            location={location}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={disabled}
          />
          <BorrowerEmail
            location={location}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={disabled}
          />
          <CoBorrowerName
            location={location}
            values={values}
            handleChange={handleChange}
            disabled={disabled}
          />
          <AdditionalBorrowers
            location={location}
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            disabled={disabled}
          />
        </Grid>
      </OutlinedSection>
      <Grid className={classes.gridRoot} container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={0} alignItems='center'>
            <SignerType
              location={location}
              values={values}
              handleChange={handleChange}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={0} alignItems='center'>
            <PartialSigning
              location={location}
              values={values}
              errors={errors}
              handleChange={handleChange}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
      <OutlinedSection title='Contact Information'>
        <Grid className={classes.gridRoot} container spacing={2}>
          <SignerPhone
            location={location}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={disabled}
          />
        </Grid>
      </OutlinedSection>

      <OutlinedSection title='Signing Information'>
        <Grid className={classes.gridRoot} container spacing={2}>
          {/* { location === 'location_one' &&
            <AdditionalClientEmail
              location={location}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              disabled={disabled}
            />
          } */}
          <SignerAddress
            location={location}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            disabled={disabled}
          />
          <PropertyLocation
            location={location}
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            disabled={disabled}
          />
        </Grid>
      </OutlinedSection>
      <Grid className={classes.gridRoot} container spacing={2}>
        <SpecialInstruction
          location={location}
          values={values}
          handleChange={handleChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export { OrdersForm };
