import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import { SettingsProvider } from './ThemeComponents/contexts/SettingsContext';
import { App } from './App';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'rc-time-picker/assets/index.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <SettingsProvider>
    <RecoilRoot>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <App />
      </LocalizationProvider>
    </RecoilRoot>
  </SettingsProvider>
);
