import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './Styles';

const PasswordChecklist = props => {
  const { value, minLength } = props;
  const classes = useStyles();

  const rules = {
    length: {
      valid: value.length >= (minLength || 100),
      message: 'Password has more than ' + minLength + ' characters.'
    },
    specialChar: {
      // eslint-disable-next-line
      valid: /[~`!#$%\^&*@+=\-\[\]\\';,/{}|\\':<>\?\.]/g.test(value),
      message: 'Password has special characters.'
    },
    number: {
      valid: /\d/g.test(value),
      message: 'Password has a number.'
    },
    capital: {
      valid: (function() {
        let i = 0;
        if (value.length === 0) {
          return false;
        }
        while (i < value.length) {
          const character = value.charAt(i);
          if (character === character.toLowerCase()) {
          } else if (character === character.toUpperCase()) {
            return true;
          }
          i++;
        }
        return false;
      })(),
      message: 'Password has a capital letter.'
    }
  };

  return (
    <Box
      className={classes.checklistMargin}
      display="flex"
      flexDirection="Column"
    >
      <Typography
        className={`${classes.smFont} ${
          rules.length.valid ? classes.valid : classes.invalid
        } `}
      >
        {rules.length.message}
      </Typography>
      <Typography
        className={`${classes.smFont} ${
          rules.specialChar.valid ? classes.valid : classes.invalid
        } `}
      >
        {rules.specialChar.message}
      </Typography>
      <Typography
        className={`${classes.smFont} ${
          rules.number.valid ? classes.valid : classes.invalid
        } `}
      >
        {rules.number.message}
      </Typography>
      <Typography
        className={`${classes.smFont} ${
          rules.capital.valid ? classes.valid : classes.invalid
        } `}
      >
        {rules.capital.message}
      </Typography>
    </Box>
  );
};

export { PasswordChecklist };
