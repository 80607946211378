import * as Yup from 'yup';

export const initialValues = {
  email: '',
  submit: null
};

export const emailSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required')
});
