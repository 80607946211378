import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';

import RenderRoutes from './Routes';

import GlobalStyles from './ThemeComponents/components/GlobalStyles';
import ScrollReset from './ThemeComponents/components/ScrollReset';
import SettingsNotification from './ThemeComponents/components/SettingsNotification';
import useSettings from './ThemeComponents/hooks/useSettings';
import { createThemes } from './ThemeComponents/theme';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createMemoryHistory();

const App = () => {
  const { settings } = useSettings();

  const theme = createThemes({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
          <SnackbarProvider dense maxSnack={3}>
            <Router location={history.location} navigator={history}>
                <GlobalStyles />
                <ScrollReset />
                <SettingsNotification />
                <RenderRoutes />
            </Router>
          </SnackbarProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export { App };
