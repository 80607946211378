import React, { useState } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil'
import { Typography, Card, CardContent, Box, Button, FormHelperText, TextField, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';

import { storage } from '../Services/storage';

import { Page } from '../Components/Page';
import { Schema } from '../Common/Rules/ChangePassword';
import { PasswordChecklist } from 'src/Components/PasswordChecklist';

import { changePassword } from '../Recoil/Selectors/Auth';
import { userInfo } from '../Recoil/Atoms/Login';

import { useStyles } from './Styles/ChangePassword';
import { marginStyles } from '../Common/Styles/Margins';

const ChangePassword = () => {
  const classes: any = useStyles();
  const marginClasses = marginStyles();
  const [alert, setAlert] = useState(null);
  const [user, setUser] = useRecoilState(userInfo);

  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { resetForm }) => {
    const res = await snapshot.getPromise(changePassword({
      currentPassword: values.currentPassword,
      newPassword: values.newPassword
    }));
    const userInfo = user?.user || user
    if (res.success) {
      setAlert({ status: 'success', message: 'Password Updated' })
      if (userInfo?.isTempPass === '1') {
        setUser({...user, isTempPass: '0', user: { ...userInfo, isTempPass: '0' }})
        storage.set('user', JSON.stringify({...user, isTempPass: '0', user: { ...userInfo, isTempPass: '0' } }));
      }
      resetForm();
    } else {
      setAlert({ status: 'error', message: res?.result?.message || 'Failed to update password' })
    }
  });

  return (
    <Page title='Change Password'>
      <div className={classes.PageTitleWrapper}>
        <Typography className={classes.pageTitle}>
          Change Password
        </Typography>
      </div>
      <Card classes={{root:classes.changePasswordCard}}>
        <CardContent>
        <Formik
            initialValues={
              {
              currentPassword: '',
              newPassword: '',
              confirmPassword: '',
            }
          }
          validationSchema={ Schema }
          onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              errors
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  label='Current Password'
                  name='currentPassword'
                  type='password'
                  variant='outlined'
                  size='small'
                  fullWidth
                  classes={{root:classes.passwordField}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.currentPassword}
                  helperText={touched.currentPassword as Boolean && errors.currentPassword as String}
                  error={Boolean(touched.currentPassword && errors.currentPassword)}
                />
                <TextField
                  label='New Password'
                  name='newPassword'
                  type='password'
                  variant='outlined'
                  size='small'
                  fullWidth
                  classes={{root:classes.passwordField}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                />
                <PasswordChecklist minLength={8} value={values.newPassword} iconSize={12} />
                <TextField
                  label='Re-type Password'
                  name='confirmPassword'
                  type='password'
                  variant='outlined'
                  size='small'
                  fullWidth
                  classes={{root:classes.passwordField}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword as Boolean  && errors.confirmPassword as String}
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                />
                {errors.submit && (
                  <FormHelperText error>
                    {errors.submit as String}
                  </FormHelperText>
                )}
                { alert &&
                  <Box mb={3}>
                    <Alert severity={alert.status}>{alert.message}</Alert>
                  </Box>
                }
                <Box mt={3}>
                  <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    fullWidth={false}
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <CircularProgress className={marginClasses.mr2} color='inherit' size={25} />}
                    Submit
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Page>
  );
};

export { ChangePassword };
