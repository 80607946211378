import * as Yup from 'yup';

import { EmailRegex } from '../Regex/Email';
import { checkEmailAvailablility } from '../../Utils/CheckEmail';

let email = '';
let validated = true;

export const initialValues = {
  Contact_First_Name: '',
  Contact_Last_Name: '',
  Contact_Email: '',
  Contact_Work_Phone: '',
  Contact_Cell: '',
  authenticationType: 'password',
  company_name: '',
  divisionName: '',
  Contact_Team_Manager: ''
};

export const validationSchema = Yup.object().shape({
  Contact_First_Name: Yup
    .string()
    .required('First Name is required'),
  divisionName: Yup
    .string()
    .required('Division Name is required'),
  Contact_Last_Name: Yup
    .string()
    .required('Last Name is required'),
  Contact_Email: Yup
    .string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required')
    .test('checkEmailAvailablility', 'Email not available!', async (value) => {
      if (value && value.length > 9 && email !== value && EmailRegex.test(value)) {
        const res: any = await checkEmailAvailablility(value);
        email = value;
        if (!res || res.success) {
          validated = true;
          return true;
        }
        validated = false;
        return false;
      }

      return validated;
    }),
  Contact_Work_Phone: Yup
    .string()
    .required('Work Phone Number is required'),
  company_name: Yup
    .string()
    .required('Company Name is required'),
  Contact_Team_Manager: Yup
    .string()
});
