import React from 'react';
import { Grid, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useStyles } from '../Styles/Fields';

import '../../../Common/Styles/style.css';
import dayjs from 'dayjs';

const AppointmentDateTime = ({ location, values, errors, touched, handleBlur, setFieldValue, disabled }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={5}>
        <DatePicker
          label='Appointment Date'
          name={`${location}.w_Appointment_Date`}
          format='MM/DD/YYYY'
          disablePast={true}
          value={dayjs(values.w_Appointment_Date)}
          onChange={(e) => {
            setFieldValue(`${location}.w_Appointment_Date`, dayjs(e)?.format('MM/DD/YYYY'))
          }}
          disabled={disabled}
        />
        {touched[location] && errors[location] && errors[location].w_Appointment_Date &&
          <FormHelperText error>
            {errors[location].w_Appointment_Date}
          </FormHelperText>
        }
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControlLabel
          className={classes.mt10}
          label='Open Time'
          control={
            <Checkbox
              color='primary'
              name={`${location}.w_Appointment_Time_type`}
              checked={values.w_Appointment_Time_type === 'OT'}
              onChange={(e) => setFieldValue(`${location}.w_Appointment_Time_type`, e.target.checked ? 'OT' : 'SC')}
            />
          }
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TimePicker
          name={`${location}.w_Appointment_Time`}
          defaultValue={values.w_Appointment_Time}
          value={values.w_Appointment_Time}
          onChange={(e) => setFieldValue(`${location}.w_Appointment_Time`, e ? moment(e, 'hh:mm A') : '')}
          showSecond={false}
          use12Hours={true}
          allowEmpty={true}
          minuteStep={15}
          disabled={values.w_Appointment_Time_type === 'OT' || disabled}
          placeholder='Appointment Time'
        />
        {errors[location] && errors[location].w_Appointment_Time &&
          <FormHelperText error>
            {errors[location].w_Appointment_Time}
          </FormHelperText>
        }
      </Grid>
    </>
  );
}

export { AppointmentDateTime };
