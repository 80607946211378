import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const BorrowerEmail = ({ location, values, errors, touched, handleChange, handleBlur, disabled }) => {
  return (
    <Grid item xs={12} sm={12} md={4}>
      <TextField
        label="Borrower's Email"
        name={`${location}.f_borrower_email`}
        variant='outlined'
        size='small'
        fullWidth
        margin='none'
        value={values.f_borrower_email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={(touched[location] && errors[location]) && errors[location].f_borrower_email}
        error={Boolean((touched[location] && errors[location]) && errors[location].f_borrower_email)}
        disabled={disabled}
      />
    </Grid>
  );
}

export { BorrowerEmail };
