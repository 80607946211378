import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  Contact_First_Name: Yup
    .string().required('Please Enter Your First Name'),
  Contact_Last_Name: Yup
    .string().max(255).required('Please Enter Your Last Name'),
  Contact_Email: Yup
    .string().email('Invalid Email').required('Please Enter a Valid Email'),
  Contact_Work_Phone: Yup
    .string().max(255).required('Please Enter a Valid Phone Number'),
  Contact_Cell: Yup
    .string().max(255).required('Please Enter a Valid Phone Number'),
  authenticationType: Yup
    .string().max(255).required('Please Select Authentication Type'),
});

export { validationSchema };
