import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const CoBorrowerName = ({ location, values, handleChange, disabled }) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          label="Co-Borrower's First Name"
          name={`${location}.w_Co_Borrower_First_Name`}
          variant='outlined'
          size='small'
          fullWidth
          margin='none'
          value={values.w_Co_Borrower_First_Name}
          onChange={ e => { 
            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
            handleChange(e)}}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          label="Co-Borrower's Last Name"
          name={`${location}.w_Co_Borrower_Last_Name`}
          variant='outlined'
          size='small'
          fullWidth
          margin='none'
          value={values.w_Co_Borrower_Last_Name}
          onChange={ e => { 
            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substr(1).toLowerCase();
            handleChange(e)}}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export { CoBorrowerName };
