import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
};
const Toast = ({ message = '', type = 'success', open = false, setToast }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() => setToast({
          message: '',
          type: 'success',
          open: false
        })}
      >
        <Alert
          onClose={() => setToast({
            message: '',
            type: 'success',
            open: false
          })}
          severity={type}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export { Toast };
