import React, { useState, useRef, useEffect } from 'react';
import { useRecoilValue, useRecoilCallback, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik, Form } from 'formik';
import moment from 'moment-timezone'

import { OrdersForm } from './OrderForm';
import { SingleLoanNumber } from './Fields/SingleLoanNumber';

import { currentOrder, refetchGetOrderById } from '../../Recoil/Atoms/Orders';
import { editOrder, sendMailToPacdoc, getOrderById } from '../../Recoil/Selectors/Orders';

import { validationSchemaForExisting } from '../../Common/Rules/OrdersForm';
import { createPayload } from '../../Common/CreatePayload';
import { updateAdditionalBorrowers } from '../../Common/UpdateAdditionalBorrowers';
import { getDifference } from '../../Common/getDifference';
import { PageLoad } from '../../Components/LoadingScreen/PageLoad';


const EditOrder = ({ bindSubmitForm, handleLoading }) => {
  const alertRef = useRef(null);
  const [alert, setAlert] = useState(null);
  const refetchOrder = useSetRecoilState(refetchGetOrderById)

  useEffect(() => {
    refetchOrder( (n) => n + 1 )
    // eslint-disable-next-line 
  }, [])

  const selectedOrder: any = useRecoilValue(currentOrder);

  const orderRequest: any = useRecoilValueLoadable(getOrderById(selectedOrder))
  const order = orderRequest?.contents?.result || {}


  const onSubmit = useRecoilCallback(({ snapshot }) => async (values: any, { resetForm }) => {
    values.w_Loan_Number = [values.location_one.w_Loan_Number];

    const params = createPayload(values);
    const updatedAdditionalBorrowers = updateAdditionalBorrowers(values, order);
    params.location_one.additionalBorrowersModel = [...updatedAdditionalBorrowers];
    handleLoading(true);

    const difference = getDifference(params.location_one, order);

    const fieldsTODelete = ['f_borrower_address', 'f_borrower_city', 'f_borrower_state', 'f_borrower_zip']
    fieldsTODelete.map(k => {
      delete params.location_one[k]
      return k
    });

    const res = await snapshot.getPromise(editOrder(params));
    snapshot.getPromise(sendMailToPacdoc({
      f_loan_number: params.w_Loan_Number,
      status: 'UPDATED',
      updated: difference
    }));

    if (res.success) {
      resetForm();
      handleLoading(false, 'Order Updated Successfully!', 'success');
    } else {
      setAlert({
        status: 'error',
        message: res?.result?.data?.message
      });
      alertRef.current.scrollIntoView();
      handleLoading(false);
    }
  });

  return (
    orderRequest?.state === 'loading' ?
    <PageLoad />
    :
    <Formik
      initialValues={{ location_one: { ...order, 
        w_Appointment_Date: moment(order.w_Appointment_Date, 'MM/DD/YYYY'), 
        w_Appointment_Time: moment(order.w_Appointment_Time, 'hh:mm A')  } 
      }}
      validationSchema={validationSchemaForExisting}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        submitForm
      }: any) => {
        bindSubmitForm(submitForm);
        return (
          <Form onSubmit={handleSubmit}>
            <SingleLoanNumber
              value={values.location_one.w_Loan_Number}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              disabled={false}
            />
            <OrdersForm
              location='location_one'
              values={{ ...values.location_one }}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              disabled={false}
            />
            { alert &&
              <Box mt={3}>
                <Alert ref={alertRef} severity={alert.status}>{alert.message}</Alert>
              </Box>
            }
          </Form>
        )
      }}
    </Formik>
  );
};

export { EditOrder };
