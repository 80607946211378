import React from 'react';
import { useRecoilState } from 'recoil';
import { 
  Button, 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
} from '@material-ui/core';

import { Search } from './Search'

import { division } from '../../Recoil/Atoms/Divisions';

import { useStyles } from './Styles/Actions';

const Actions = (props: any) => {
  const classes = useStyles();
  const [divisionID, selectDivision] = useRecoilState(division);

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.grid}>
        <Button
          className={classes.button}
          color='primary'
          size='large'
          variant='contained'
          disabled={!divisionID}
          onClick={() => props.openModal('New Order', true)}
        >
          New Order
        </Button>
      </Grid>
      <Grid item className={classes.selectBox}>
        <FormControl margin='dense' variant='outlined'>
          <InputLabel>Divisions</InputLabel>
          <Select
            className={classes.select}
            defaultValue={divisionID}
            value={divisionID}
            onChange={(e: any) => selectDivision(e.target.value)}
            label='Divisions'
          >
            <MenuItem value={0}>Select Division</MenuItem>
            {props.divisions.map((d) =>
              <MenuItem key={`division-${d.divisionID}`} value={d.divisionID}>
                {d.divisionName}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item className={classes.grid}>
        <Search SearchStateChanger={props.SearchStateChanger}/>
      </Grid>
    </Grid>
  );
};

export { Actions };

