import { makeStyles } from '@material-ui/core/styles';

export const useHOStyles = makeStyles(() => ({
  textWithIcon: {
    margin: '10px 0',
    display: 'flex',
  },
  icon: {
    marginRight: 5
  }
}));
