import React from 'react'
import { Box, Typography, Grid, CircularProgress, SvgIcon, Paper } from '@material-ui/core';
import { Trash } from 'react-feather';
import moment from 'moment';

import { useStyles } from './Style/Style';

const FileList = ({ loading, total, files, getFile, removeFile, title = 'List of Files', signer = false, nofile = 'NO FILE HAS BEEN UPLOADED YET', viewedFiles = [] }) => {
  const classes = useStyles();
  return (
    <Grid direction='column' container classes={{ root: classes.fileListWrapper }}>
      <Paper className={classes.paper}>
      { loading ?
        <Box my={2} display='flex' justifyContent='center'>
          <CircularProgress />
        </Box> :
        total === 0 && loading?
          <Typography variant='h4' align='center'> { nofile.toLocaleUpperCase() }</Typography> :
          <>
            <Typography variant='h4'>{title}</Typography>
            { files.map(file => {
              const time = file.created ? moment(new Date(file.created)).format('MM/DD/YYYY hh:mm A') : '';
              return (
                <>
                  <Box
                    className={classes.fileContainer}
                    key={file.created}
                    display='grid'
                    gridAutoFlow='column'
                    gridTemplateColumns={'1fr 1fr 1fr'}
                    px={2}
                    py={1}
                    mt={2}
                  >
                    <Typography
                      className={`${classes.fileLabel} ${classes.thirdWidth}`}
                      color='primary'
                      variant='body2'
                      onClick={() => getFile(file.name)}
                    >
                      {file.name}
                    </Typography>
                    <Typography className={classes.thirdWidth} variant='caption'>{title==='List of Merged Files'?'Merged By:':'Uploaded By:'} { signer ? 'Signer' : file.createdBy}</Typography>
                    <Typography className={classes.thirdWidth} style={{width: '32%'}} variant='caption'>{title==='List of Merged Files'?'Merged On:':'Uploaded On:'} {time}</Typography>
                    {
                        removeFile && 
                        <SvgIcon
                          className={classes.deleteIcon}
                          fontSize='small'
                          color='primary'
                          onClick={() => removeFile(file.name)}
                        >
                          <Trash />
                        </SvgIcon>
                      }
                  </Box>
                  <Box px={1}>
                    {
                      viewedFiles.map( viewedFile => {
                        if (viewedFile?.mergedDocs.length > 0) {
                          let ids = viewedFile?.mergedDocs?.split(',')
                          return (
                            <Typography variant='caption'>
                              { 
                                ids.includes(`${file.documentID}`)
                                && `Viewed By: ${viewedFile?.viewed[0]?.signer?.Signer_First} ${viewedFile?.viewed[0]?.signer?.Signer_Last} | Viewed On: ${moment(viewedFile?.viewed[0]?.date_time).format('MM/DD/YYYY hh:mm A')}`
                              }
                            </Typography>
                          )
                        }
                        return <></>
                      })
                    }
                  </Box>
                </>
              )
            })}
          </>
      }
      </Paper>
    </Grid>
  )
}

export { FileList };
