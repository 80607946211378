import React, { useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography, CircularProgress, Box } from '@material-ui/core';
import moment from 'moment-timezone';

import { OrdersTablesHead } from './OrdersTableHead';
import { FileUploadModal } from '../FileUpload/FileUploadModal'
import { Toast } from '../../Components/Toasts';
import { TrackingStatus } from './TrackingStatus';
import { GeneralModal } from './../Modal'
import { AuditTrail } from './AuditTrail'

import { currentOrder, pagination, refetchConfirmation } from '../../Recoil/Atoms/Orders';
import { division } from '../../Recoil/Atoms/Divisions';
import { viewClientConfirmation } from '../../Recoil/Selectors/Orders';

import { useStyles } from './Styles/OrdersList';
import { SignerInfo } from './SignerInfo';
import { FileText } from 'react-feather';

type Order = 'asc' | 'desc';

const OrdersList = (props: any) => {
  const classes = useStyles();
  const { orders, total, openModal, ordersRefetch, orderLoading } = props;
  const statuses = ['Assigned', 'In Process']
  const refetchClientConfirmation = useSetRecoilState(refetchConfirmation)
  const [trail, setTrail] = useState(undefined)

  const [trackIngStatusModal, handleTrackIngStatus] = useState(null);
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false
  });

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('w_Appointment_Date');
  const [fileModal, handleFileModal] = useState(false);
  const [signerInfo, setSignerInfo] = useState(null);


  const setCurrentOrder = useSetRecoilState(currentOrder);
  const [pageInfo, setPageInfo] = useRecoilState(pagination);
  const divisionID = useRecoilValue(division);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property) => {
    const isAsc = orderBy === property && order === 'asc';

    setPageInfo({
      ...pageInfo,
      orderBy: property,
      orderType: isAsc ? 'desc' : 'asc',
    });

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage + 1 || 1
    });
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      ...pageInfo,
      limit: parseInt(e.target.value, 10)
    });
  };

  const viewConfirmation = useRecoilCallback(({ snapshot }) => async (Id: any) => {
    const res: any = await snapshot.getPromise(viewClientConfirmation({ Id }));
    refetchClientConfirmation((n: any) => ++n)
    if (res.success) {
      let windowNavigator: any;
      windowNavigator = window.navigator;
      const fileURL = res.result;
      if (windowNavigator && windowNavigator.msSaveOrOpenBlob) {
        let blob = new Blob([fileURL], { type: "text/html" });
        windowNavigator.msSaveOrOpenBlob(blob, 'Client Confirmation.pdf');
      } else {
        const fetchedFile = window.URL.createObjectURL(fileURL);
        window.open(fetchedFile)
      }
    } else {
      setToast({
        message: 'Failed to Fetch Confirmation. Please Try Again',
        type: 'error',
        open: true
      });
    }
  })

  return (
    <div className={classes.root} hidden={props.hide}>
      {trackIngStatusModal && (
        <TrackingStatus
          setRefetchOrders={ordersRefetch}
          order={trackIngStatusModal}
          handleTrackIngStatus={handleTrackIngStatus}
        />
      )}
      <Toast message={toast.message} type={toast.type} open={toast.open} setToast={setToast} />
      {divisionID !== 0 && props.loading ?
        <Box width='100%' padding='10px 0' display='flex' justifyContent='center'>
          <CircularProgress />
        </Box> :
        <>
          <SignerInfo signer={signerInfo} close={setSignerInfo}></SignerInfo>
          {orderLoading &&
            <Box style={{ display: 'flex' }} className={classes.loader}>
              <CircularProgress />
            </Box>
          }
          <TableContainer hidden={props.loading}>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              size='medium'
              aria-label='enhanced table'
            >
              <OrdersTablesHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {
                  orders.map((row: any, index: any) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.Id}
                      >
                        <TableCell>
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          <Box display='flex' alignContent='center' alignItems='center'>
                            {row.w_Loan_Number}
                          </Box>
                        </TableCell>
                        <TableCell>{row.w_Borrower_Last_Name}</TableCell>
                        <TableCell>{row.w_Appointment_Date}</TableCell>
                        <TableCell>{row.w_Appointment_Time}</TableCell>
                        <TableCell>{row.f_status_web}
                          {row?.signer && (
                            <Typography
                              color='primary'
                              variant='body2'
                              className={classes.cursorPointer}
                              onClick={e => setSignerInfo(
                                { ...row?.signer, w_Loan_Number: row?.w_Loan_Number }
                              )
                              }
                            >
                              Signer Info
                            </Typography>)}
                        </TableCell>
                        <TableCell>
                          <a target='_blank' rel='noopener noreferrer' href={`https://www.fedex.com/fedextrack/?tracknumbers=${row?.w_Return_Tracking}&cntry_code=us`}>{row?.w_Return_Tracking}</a>
                        </TableCell>
                        <TableCell>
                          <Typography
                            className={classes.actionLabels}
                            color='primary'
                            variant='body2'
                            onClick={(e) => {
                              e.stopPropagation();
                              setCurrentOrder({
                                ...row,
                                w_Appointment_Time: moment(row.w_Appointment_Time, 'hh:mm A')
                              });
                              handleFileModal(true);
                            }}
                          >

                            <FileText size='14' /> {row?.documentCount || 0} Document(s) View/Upload
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            className={classes.actionLabels}
                            color='primary'
                            variant='body2'
                            onClick={(e) => {
                              e.stopPropagation();
                              openModal('View Order', true);
                              setCurrentOrder({
                                ...row,
                                w_Appointment_Time: moment(row.w_Appointment_Time, 'hh:mm A')
                              });
                            }}
                          >
                            View
                          </Typography>
                          {statuses.includes(row.f_status_web) ?
                            <>
                              <Typography
                                className={classes.actionLabels}
                                color='primary'
                                variant='body2'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openModal('Edit Order', true);
                                  setCurrentOrder({
                                    ...row,
                                    w_Appointment_Time: moment(row.w_Appointment_Time, 'hh:mm A')
                                  });
                                }}
                              >
                                Edit
                              </Typography>
                              <Typography
                                className={classes.actionLabels}
                                color='primary'
                                variant='body2'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openModal('Cancel Order', true);
                                  setCurrentOrder(row);
                                }}
                              >
                                Cancel
                              </Typography>
                            </> : ""
                          }
                          <Typography
                            className={classes.actionLabels}
                            color='primary'
                            variant='body2'
                            onClick={(e) => {
                              e.stopPropagation();
                              openModal('Duplicate Order', true);
                              setCurrentOrder({
                                ...row,
                                w_Appointment_Time: moment(row.w_Appointment_Time, 'hh:mm A')
                              });
                            }}
                          >
                            Duplicate
                          </Typography>
                          {
                            row?.f_signer_id ?
                              <Typography
                                className={classes.actionLabels}
                                color='primary'
                                variant='body2'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  viewConfirmation(row?.Id)
                                }}
                              >
                                View Confirmation
                              </Typography> : ""
                          }
                          <Typography
                            className={classes.actionLabels}
                            color='primary'
                            variant='body2'
                            onClick={(e) => setTrail(row)}
                          >
                            Audit Trail
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            hidden={props.loading}
            rowsPerPageOptions={[10, 20, 30]}
            component='div'
            count={total || 0}
            rowsPerPage={pageInfo.limit}
            page={orders.length > 0 ? pageInfo.page - 1 : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {fileModal &&
            <FileUploadModal open={fileModal} handleFileModal={handleFileModal} />
          }
        </>
      }

      <GeneralModal
        open={trail}
        title={'Audit Trail'}
        loading={false}
        openModal={() => setTrail(undefined)}
        noSubmit={true}
      >
        <AuditTrail row={trail} />
      </GeneralModal>
    </div>
  );
}

export { OrdersList };
