import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/ThemeComponents/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    actions: {
      display: 'flex',
      flexDirection: 'column'
    },
    actionLabels: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    loader: {
      justifyContent: 'center',
      alignSelf: 'center',
      textAlign: 'center'
    },
    cursorPointer: {
      cursor: 'pointer'
    }
  })
);

export { useStyles };
