import React, { useRef, useState } from 'react';
import { useRecoilValueLoadable, useRecoilCallback, useSetRecoilState, useRecoilValue } from 'recoil'
import type { FC } from 'react';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  Badge,
  CircularProgress,
} from '@material-ui/core';
import { Bell } from 'react-feather';
import type { Theme } from 'src/ThemeComponents/theme';
import { fetchNotifications, markAnnouncement } from '../../Recoil/Selectors/Notifications';
import { refetchNotifications } from '../../Recoil/Atoms/Notifications';
import { userInfo } from '../../Recoil/Atoms/Login';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  spinnerStyle: {
  display:'flex',
  justifyContent:'center',
  padding:'10px 0x'
  },
}));

const Notifications: FC = () => {
  const classes = useStyles();
  const fetchedNotifications: any = useRecoilValueLoadable(fetchNotifications)
  const userAtom = useRecoilValue(userInfo);
  const { user } = userAtom
  const notifications = fetchedNotifications?.contents?.result || [];
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const refetch = useSetRecoilState(refetchNotifications);
  const forceUpdate = () => refetch((n) => n + 1);

  const handleOpen = (): void => {
    setOpen(true);
    forceUpdate()
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const markNotification = useRecoilCallback(({ snapshot }) => async (id: any) => {
    await snapshot.getPromise(markAnnouncement({
      announcement_id: id,
      contact_id:user.Id
    }));

    forceUpdate();
  })

  const getNotificationIds = () => {
    const announcementIds = []
    notifications.forEach(notification => {
      announcementIds.push(notification.id)
    });
    return announcementIds;
  }

  return (
    <>
      <Tooltip title='Notifications'>
        <Badge
          color='secondary'
          variant='dot'
          invisible={notifications.length < 1}
          classes={{ badge: classes.badge }}
        >
          <IconButton
            color='inherit'
            ref={ref}
            onClick={handleOpen}
          >
            <SvgIcon>
              <Bell />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant='h5'
            color='textPrimary'
          >
            Notifications
          </Typography>
        </Box>
        {fetchedNotifications.state === 'loading'
        ?
        <div className={classes.spinnerStyle}>
          <Box p={2}>
            <CircularProgress />
          </Box>
        </div>
        :
        notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant='h6'
              color='textPrimary'
            >
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              { notifications.map((notification) => {
                return (
                  <ListItem
                    button
                    divider
                    key={notification.id}
                    onClick={()=>{markNotification([notification.id])}}
                  >
                    <ListItemText
                      primary={<Typography noWrap>{notification.announcement}</Typography>}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary', }}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box
              p={1}
              display='flex'
              justifyContent='center'
            >
              <Button
                size='small'
                onClick={()=>{
                  const ids = getNotificationIds()
                  markNotification(ids)
                }}
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
