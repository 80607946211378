import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, SvgIcon } from '@material-ui/core';
import { XCircle } from 'react-feather';

import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';

import { states } from '../../../Common/States';

import { useStyles } from '../Styles/Fields';

const PropertyLocation = ({ location, values, handleChange, setFieldValue, disabled }) => {
  const classes = useStyles();
  const [propertyLocation, handlePropertyLocation] = useState(disabled);
  const [addressSearch, handleAddressSearch] = useState('');

  return (
    <>
      { !propertyLocation ?
        <Grid item xs={4}>
          <Button
            color='primary'
            variant='contained'
            onClick={() => handlePropertyLocation(true)}
          >
            Property Location
          </Button>
        </Grid> :
        <>
          <Grid item sm={12} md={12}>
            <Grid container spacing={3}>
              <Grid className={classes.pRelative} item xs={12}>
                <ReactGoogleMapLoader
                  params={{
                    key: 'AIzaSyDy51NKS3CJUDKdqCJ897ZInNDfC203BOM',
                    libraries: 'places,geocode',
                  }}
                  render={googleMaps =>
                    googleMaps && (
                      <ReactGooglePlacesSuggest
                        googleMaps={googleMaps}
                        autocompletionRequest={{ input: addressSearch }}
                        onSelectSuggest={geocodedPrediction => {
                          let address = '';
                          handleAddressSearch('');
                          geocodedPrediction.address_components.forEach(item => {
                            if (item.types[0] === 'locality') {
                              setFieldValue(`${location}.property_city`, item.short_name);
                            }
                            if (item.types[0] === 'administrative_area_level_1') {
                              setFieldValue(`${location}.property_state`, item.short_name);
                            }
                            if (item.types[0] === 'postal_code') {
                              setFieldValue(`${location}.property_zip`, item.short_name);
                            }
                            if (item.types[0] === 'street_number' || item.types[0] === 'premise') {
                              address = item.long_name;
                            }
                            if (item.types[0] === 'route' || item.types[0] === 'political') {
                              address = `${address} ${item.long_name}`;
                            }
                            setFieldValue(`${location}.property_address`, address);
                          })
                        }}
                        textNoResults='Address Not Found'
                        customRender={prediction => (
                          <Typography variant='body2' className={classes.addressItem}>
                            { prediction ?
                              prediction.description :
                              'Address Not Found'
                            }
                          </Typography>
                        )}
                      >
                        <TextField
                          label='Address'
                          name={`${location}.property_address`}
                          variant='outlined'
                          size='small'
                          fullWidth
                          value={values.property_address}
                          onChange={(e) => {
                            handleChange(e);
                            handleAddressSearch(e.target.value);
                          }}
                          inputProps={{
                            autoComplete: 'none'
                          }}
                          disabled={disabled}
                        />
                      </ReactGooglePlacesSuggest>
                    )
                  }
                />
                {!disabled &&
                  <SvgIcon
                    className={classes.closeButton}
                    fontSize='small'
                    color='primary'
                    onClick={() => handlePropertyLocation(false)}
                  >
                    <XCircle />
                  </SvgIcon>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label='City'
                  name={`${location}.property_city`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={values.property_city}
                  onChange={handleChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.removeSelectMargin} fullWidth margin='dense' variant='outlined'>
                  <InputLabel>State</InputLabel>
                  <Select
                    name={`${location}.property_state`}
                    fullWidth
                    value={values.property_state}
                    onChange={handleChange}
                    label='State'
                    disabled={disabled}
                  >
                    {states.map((state) =>
                      <MenuItem key={`borrower-${state.code}`} value={state.code}>
                        {state.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label='Zip Code'
                  name={`${location}.property_zip`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={values.property_zip}
                  onChange={handleChange}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </>
  );
}

export { PropertyLocation };
