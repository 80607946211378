import React, { useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Box, Button, Dialog, DialogActions, DialogContent, Typography, Grid, CardMedia } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { Filedropzone } from './Filedropzone';
import { FileList } from './FileList';
import { GeneralModal } from './../Modal'

import { uploadFile, FetchFileList, FetchMerged, DeleteFile, FetchFile, fetchScanbacks, revisedDocumentAlert } from '../../Recoil/Selectors/Orders';
import { forceUpdateFiles, currentOrder, forceRefetchOrders, forceDownload } from '../../Recoil/Atoms/Orders';

import { useStyles } from './Style/Style';
import { displayStyles } from '../../Common/Styles/Display';

const FileUploadModal = ({ open, handleFileModal }) => {
  const classes = useStyles();
  const displayClasses = displayStyles();
  const [alert, setAlert] = useState(null);
  const todoUpdates = useSetRecoilState(forceUpdateFiles);
  const redownload = useSetRecoilState(forceDownload);
  const selectedOrder: any = useRecoilValue(currentOrder);
  const refetchOrders = useSetRecoilState(forceRefetchOrders)
  const [documents, setDocuments] = useState([])
  const [viewedDocuments, setViewedDocuments] = useState([])

  const forceUpdate = () => todoUpdates((n) => n + 1);
  const forceUpdateOrders = () => refetchOrders( (n) => n + 1 )

  const uploadedFiles: any = useRecoilValueLoadable(FetchFileList(selectedOrder));
  const merged: any = useRecoilValueLoadable(FetchMerged(selectedOrder));
  const files = uploadedFiles?.contents?.result?.data || [];
  const mergedFiles = merged?.contents?.result?.data || [];
  const total = files.length;
  const scanbacksRequest: any = useRecoilValueLoadable(fetchScanbacks(selectedOrder));
  const scanbacks = scanbacksRequest?.contents?.result?.data || [];
  const [file, setFile] = useState({ open: false, fileURL: undefined })
  const scanbacksTotal = scanbacks.length;

  useEffect(()=>{
    if (uploadedFiles.state !== 'loading')
      setDocuments(uploadedFiles?.contents?.result?.data || [])
  }, [uploadedFiles])

  useEffect(()=>{
    if (merged.state !== 'loading') {
      let files = mergedFiles.filter( file => file.viewed.length > 0)
      setViewedDocuments(files)
    }
  }, [merged, mergedFiles])

  const uploadRequest = useRecoilCallback(({ snapshot }) => async (file: any) => {
    setAlert(null);
    const res: any = await snapshot.getPromise(uploadFile({ file, destination: `borrower/${selectedOrder.Id}` }));
    forceUpdate();
    forceUpdateOrders()
    if (!res.success) {
      setAlert({
        status: 'warning',
        message: 'File did not upload.'
      });
    }
    return res
  })

  const removeFile = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    await snapshot.getPromise(DeleteFile({
      folder: `borrower/${selectedOrder.Id}`, filename
    }));
    if (mergedFiles.length > 0) {
      await snapshot.getPromise(revisedDocumentAlert(selectedOrder));
    }
    forceUpdate();
    forceUpdateOrders();
  });

  const getFile = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    const res: any = await snapshot.getPromise(
      FetchFile({
        folder: `borrower/${selectedOrder.Id}`, filename, action: 'view/download', orderid: selectedOrder.Id
      })
    );
    redownload( n => n + 1 );
    if (res.success) {
      let windowNavigator: any;

      windowNavigator = window.navigator;
      const fileURL = res.result;
      if (windowNavigator && windowNavigator.msSaveOrOpenBlob) {
        let blob = new Blob([fileURL], { type: "text/html" });
        windowNavigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const fetchedFile = window.URL.createObjectURL(fileURL);
      window.open(fetchedFile)
      }
    }
  })

  const getScanback = useRecoilCallback(({ snapshot }) => async (filename: any) => {
    const res: any = await snapshot.getPromise(
      FetchFile({
        folder:
          `borrower/${selectedOrder.Id}/signers/scanbacks`, filename, action: 'view/download', orderid: selectedOrder.Id
      })
    );
    redownload( n => n + 1 );
    if (res.success) {
      let windowNavigator: any;

      windowNavigator = window.navigator;
      const fileURL = res.result;
      if (windowNavigator && windowNavigator.msSaveOrOpenBlob) {
        let blob = new Blob([fileURL], { type: "text/html" });
        windowNavigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const fetchedFile = window.URL.createObjectURL(fileURL);
      window.open(fetchedFile)
      }
    }
  })

  return (
    <>
    <Dialog
      open={open}
      maxWidth='md'
      fullWidth={true}
      onClose={() => {
        handleFileModal(false)
      }}
    >
      <MuiDialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant='h4'>Upload File</Typography>
      </MuiDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <Filedropzone
            uploadRequest={uploadRequest}
            Id={selectedOrder.Id}
            files={documents}
            mergedFiles={mergedFiles}
            disabled={!(selectedOrder?.f_status_web === 'Assigned' || selectedOrder?.f_status_web === 'In Process')}
          />
          {alert &&
            <Box className={displayClasses.w100} my={3}>
              <Alert severity={alert.status}>{alert.message}</Alert>
            </Box>
          }
          <FileList
            title='List of Scanbacks'
            loading={scanbacksRequest.state === 'loading'}
            total={scanbacksTotal}
            files={scanbacks}
            getFile={getScanback}
            removeFile={null}
            signer={true}
            nofile='No Scanback Has Been Uploaded Yet'
          />
        <FileList
            loading={uploadedFiles.state === 'loading'}
            total={total}
            files={files}
            getFile={getFile}
            viewedFiles={viewedDocuments}
            removeFile={selectedOrder?.f_status_web === 'Cancelled' || selectedOrder?.f_status_web === 'Completed' ? null : removeFile}
          />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color='secondary'
          onClick={() => {
            handleFileModal(false)
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
    
    <GeneralModal
    title='Document Viewer'
    submitLabel='OK'
    noSubmit
    openModal={() => setFile({ open: false, fileURL: undefined })}
    open={file.open}
    maxWidth='xl'
    >
      <CardMedia
        component='iframe'
        src={file.fileURL}
        title='Signer Confirmation'
        className={classes.fullHeight}
      />
    </GeneralModal>
  </>
  );
}

export { FileUploadModal };
