import { atom } from 'recoil';

const orders = atom({
  key: 'orders',
  default: []
});

const currentOrder = atom({
  key: 'currentOrder',
  default: {}
});

const pagination = atom({
  key: 'pagination',
  default: {
    page: 1,
    limit: 20,
    orderBy: 'w_Appointment_Date',
    orderType: 'desc'
  }
});

const refetchOrders = atom({
  key: 'refetchOrders',
  default: 0
});

const forceUpdateFiles = atom({
  key: 'forceUpdateFiles',
  default: 0
});

const searchedOrders = atom({
  key: 'searchedOrders',
  default: {
    field: '',
    value: ''
  }
});

const forceDownload = atom({
  key: 'forceDownload',
  default: 0
});

const forceRefetchOrders = atom({
  key: 'forceRefetchOrders',
  default: 0
});

const refetchConfirmation = atom({
  key: 'refetchConfirmation',
  default: 0
});

const refetchGetOrderById = atom({
  key: 'refetchGetOrderById',
  default: 0
});

const resendMailToPacdoc = atom({
  key: 'resendMailToPacdoc',
  default: 0
});

export { 
  orders, 
  currentOrder, 
  pagination, 
  refetchOrders, 
  forceUpdateFiles, 
  searchedOrders, 
  forceRefetchOrders,
  refetchConfirmation,
  forceDownload,
  refetchGetOrderById,
  resendMailToPacdoc
};
